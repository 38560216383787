import React, { useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import "../assets/scss/request.scss";
import { Link } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { toast } from "react-toastify";
const RequestSend = () => {
  const defaultValues = {
    name: "",
    surname: "",
    email: "",
    company_name: "",
    company_role: "",
    student_count: "",
    country: "",
    post_code: "",
  };
  const [values, setValues] = useState(defaultValues);
  const handleValueChange = (e) => {
    setValues((value) => ({
      ...value,
      [e.target.name]: e.target.value,
    }));
  };
  const validationControl = () => {
    let isValid = true;
    const inputs = document.querySelectorAll(".form-input");
    inputs.forEach((input) => {
      input.classList.remove("is-invalid");
      if (input.type === "checkbox") {
        if (!input.checked) {
          isValid = false;
          input.classList.add("is-invalid");
        }
      }
      if (input.value === "") {
        isValid = false;

        input.classList.add("is-invalid");
      }
    });

    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validationControl();
    if (!valid) return false;
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi()
      .post("website/infoform", formData)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: "top-center",
          });
          setValues(defaultValues);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("İletişim formu şu anda alınamıyor.");
      });
  };
  return (
    <div className="send-request ">
      <Container className="mb-5">
        <Row className="justify-content-center">
          <Col md="9">
            <Link to={"/"} className="d-flex justify-content-center mb-4">
              <img src="./images/logo.svg" alt="eduvery" />
            </Link>
            <h4 className="request-title text-center">
              Şirketler için Eduvery İstek Formu
            </h4>
            <p className="text-center">
              Eduvery, şirketlere eğlenceli ve etkili bir öğrenme deneyimi
              sunarak takımların gelişimine katkı sağlar. Eğer 1.000 veya daha
              az kişi için hizmet almak istiyorsanız, kendi takımınızı
              oluşturabilir ve avantajlı indirimlerimizden faydalanabilirsiniz.{" "}
            </p>
          </Col>
        </Row>
      </Container>
      <hr className="my-5" />
      <Container>
        <Row
          tag={"form"}
          onSubmit={handleSubmit}
          className="justify-content-center"
        >
          <Col md="7">
            <Row className="justify-content-center">
              <Col md="5" className="mb-3">
                <Label htmlFor="name">Adınız</Label>
                <Input
                  className="form-input placeholder-white"
                  id="name"
                  name="name"
                  placeholder="Adınız"
                  value={values.name}
                  onChange={(e) => handleValueChange(e)}
                  type="text"
                />
              </Col>
              <Col md="5" className="offset-lg-1 mb-3">
                <Label htmlFor="surname">Soyadınız</Label>
                <Input
                  className="form-input placeholder-white"
                  id="surname"
                  name="surname"
                  placeholder="Soyadınız"
                  type="text"
                  value={values.surname}
                  onChange={(e) => handleValueChange(e)}
                />
              </Col>
              <Col md="5" className="mb-3">
                <Label htmlFor="email">E-Posta Adresiniz</Label>
                <Input
                  className="form-input placeholder-white"
                  id="email"
                  name="email"
                  placeholder="E-Posta Adresiniz"
                  value={values.email}
                  onChange={(e) => handleValueChange(e)}
                  type="email"
                />
              </Col>
              <Col md="5" className="offset-lg-1 mb-3">
                <Label htmlFor="company_name">Şirket Adı</Label>
                <Input
                  className="form-input placeholder-white"
                  id="company_name"
                  name="company_name"
                  placeholder="Şirket Adı"
                  value={values.company_name}
                  onChange={(e) => handleValueChange(e)}
                  type="text"
                />
              </Col>
              <Col md="5" className="mb-3">
                <Label htmlFor="company_role">Ünvanınız</Label>
                <Input
                  className="form-input placeholder-white"
                  id="company_role"
                  name="company_role"
                  placeholder="Ünvanınız"
                  value={values.company_role}
                  onChange={(e) => handleValueChange(e)}
                  type="text"
                />
              </Col>
              <Col md="5" className="offset-lg-1 mb-3">
                <Label htmlFor="student_count">Öğrenci Sayısı</Label>
                <Input
                  className="form-input placeholder-white"
                  id="student_count"
                  name="student_count"
                  placeholder="Öğrenci Sayısı"
                  onChange={(e) => handleValueChange(e)}
                  value={values.student_count}
                  type="number"
                />
              </Col>
              <Col md="5" className="mb-3">
                <Label htmlFor="country">Ülke</Label>
                <Input
                  className="form-input placeholder-white"
                  id="country"
                  name="country"
                  placeholder="Ülke"
                  onChange={(e) => handleValueChange(e)}
                  value={values.country}
                  type="text"
                />
              </Col>
              <Col md="5" className="offset-lg-1 mb-3">
                <Label htmlFor="post_code">Posta Kodu</Label>
                <Input
                  className="form-input placeholder-white"
                  id="post_code"
                  name="post_code"
                  placeholder="Posta Kodu"
                  onChange={(e) => handleValueChange(e)}
                  value={values.post_code}
                  type="number"
                />
              </Col>
              <div className="d-flex justify-content-between px-4 mt-3">
                <button
                  className="btn text-decoration-underline text-white"
                  type="button"
                  onClick={() => setValues(defaultValues)}
                >
                  Formu Temizle
                </button>
                <button className="btn btn-lg btn-white  btn-darkblue mb-lg-0 mb-3">
                  <div className="d-flex gap-2 align-items-center">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="31"
                        viewBox="0 0 32 31"
                        fill="none"
                      >
                        <circle
                          cx="16.0005"
                          cy="15.1021"
                          r="15.1021"
                          fill="#3C3744"
                        />
                        <path
                          d="M17.4969 19.9973C17.3497 19.9973 17.2026 19.9431 17.0864 19.8269C16.8618 19.6023 16.8618 19.2306 17.0864 19.006L21.3769 14.7155L17.0864 10.4249C16.8618 10.2003 16.8618 9.82859 17.0864 9.60399C17.311 9.3794 17.6828 9.3794 17.9073 9.60399L22.6083 14.305C22.8329 14.5296 22.8329 14.9013 22.6083 15.1259L17.9073 19.8269C17.7912 19.9431 17.644 19.9973 17.4969 19.9973Z"
                          fill="white"
                        />
                        <path
                          d="M22.0643 15.2965H9.03007C8.71254 15.2965 8.44922 15.0331 8.44922 14.7156C8.44922 14.3981 8.71254 14.1348 9.03007 14.1348H22.0643C22.3818 14.1348 22.6451 14.3981 22.6451 14.7156C22.6451 15.0331 22.3818 15.2965 22.0643 15.2965Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <p className="mb-0">Gönder</p>
                  </div>
                </button>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestSend;
