import React from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes.js";
import { Provider } from "react-redux";
import store from "./store/index.js";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LoaderProvider } from "./context/LoaderContext.js";

const App = () => {
  return (
    <>
      <LoaderProvider>
        <Provider store={store}>
          <RouterProvider router={routes} />
          <ToastContainer />
        </Provider>
      </LoaderProvider>
    </>
  );
};

export default App;
