import React from "react";
import { Container } from "reactstrap";
import ContactTwo from "../components/Home/ContactTwo";
import "../assets/scss/beforeQuestions.scss";

const ContactPage = () => {
  return (
    <div className="fixed-page-wrapper">
      <Container className="   contact">
          <ContactTwo />
      </Container>
    </div>
  );
};

export default ContactPage;
