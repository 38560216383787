import { Navigate, createBrowserRouter } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Home from "./pages/Home";
import QuestionsPage from "./pages/Questions";
import QuestionLayout from "./layout/QuestionLayout";
import Eduvery from "./pages/Eduvery";
import About from "./pages/About";
import Register from "./pages/Register";
import Login from "./pages/Login";
import RequestSend from "./pages/RequestSend";
import Profile from "./pages/Profile";
import Error from "./pages/Error";
import BeforeQuestions from "./pages/BeforeQuestions";
import ProfileUpdate from "./pages/ProfileUpdate";
import EmptyLayout from "./layout/EmptyLayout";
import ForgetPassword from "./pages/ForgetPassword";
import PrivateLayout from "./layout/PrivateLayout";
import QuestionFinish from "./pages/QuestionFinish";
import Gizlilik from "./pages/Sozlesmeler/Gizlilik";
import Kullanim from "./pages/Sozlesmeler/Kullanim";
import ContactPage from "./pages/ContactPage";
import NewPassword from "./pages/NewPassword";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "edutest",
        element: <Eduvery />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path:"contact",
        element:<ContactPage />
      },
      {
        path: "gizlilik-veri-politikasi",
        element: <Gizlilik />,
      },
      {
        path: "kullanim-kosullari",
        element: <Kullanim />,
      },
      {
        path: "error",
        element: <Error />,
      },
      {
        path: "*",
        element: <Navigate to={"/error"} />,
      },
    ],
  },
  {
    path: "request",
    element: <RequestSend />,
  },
  {
    path: "/",
    element: <EmptyLayout />,
    children: [
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "new-password",
        element: <NewPassword />,
      },
    ],
  },
  {
    path: "",
    element: <PrivateLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "profile-update",
        element: <ProfileUpdate />,
      },
    ],
  },
  {
    path: "",
    element: <QuestionLayout />,
    children: [
      {
        path: "questions",
        element: <QuestionsPage />,
      },
      {
        path: "before-questions",
        element: <BeforeQuestions />,
      },
      {
        path: "finish",
        element: <QuestionFinish />,
      },
    ],
  },
]);
export default routes;
