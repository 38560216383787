import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";

const Gizlilik = () => {
  return (
    <>
    <Helmet>
      <title>Gizlilik Veri Politikası</title>
      <meta name="description" content="Eduvery Gizlilik Veri Politikası" />

    </Helmet>
    <div className="fixed-page-wrapper">
      <Container>
        <Row>
          <Col xs="12">
            <div className="sozlesme">
              <h1 className="sozlesme-name">Gizlilik Veri Politikası</h1>
              <p className="sozlesme-main-text">
                Bu Gizlilik & Kişisel Veri Politikası, Eduvery tarafından
                toplanan, saklanan, işlenen, paylaşılan ve aktarılan kişisel
                veriler hakkında bilgi vermeyi amaçlamaktadır.
              </p>
              <article className="sozlesme-full">
                <h3 className="madde-baslik">
                  Gizlilik & Kişisel Veri Politikası boyunca Eduvery'dan
                  bahsedilirken "biz", "şirketimiz" gibi ifadeler
                  kullanılabilir. Eduvery şirketlerinin resmi unvanları ve
                  iletişim bilgileri bu politikanın sonunda yer almaktadır.
                </h3>
                <p>
                  "Kişisel veri", "kişisel bilgi" ve "bilgileriniz" ifadeleri
                  birbiri yerine geçecek şekilde kullanılmaktadır ve kimliği
                  belirli veya belirlenebilir bir gerçek kişiyle ilgili tüm
                  bilgileri kapsamaktadır (örneğin isim, e-posta adresi, doğum
                  tarihi, kimlik numarası). Müşterilerimiz ve son kullanıcılar
                  için kişisel verilerin önemli olduğunun bilincindeyiz ve
                  Eduvery bünyesindeki herkesin bu politikaya uyum sağlaması,
                  kişisel verilerinizin güvenliğinin her halde sağlanması için
                  gereken tüm çabayı göstereceğiz.
                </p>
                <h3 className="madde-baslik">
                  Bu Gizlilik & Kişisel Veri Politikasının amacı:
                </h3>
                <ul>
                  <li>
                    Elde ettiğimiz verileri kullanma amacımızı ve şeklimizi
                    mümkün olan en açık ve anlaşılır biçimde açıklamak;
                  </li>
                  <li>
                    Sizin izninizle hangi bilgileri elde ettiğimizi ve bu
                    bilgilerin hangi amaçlarla ve nasıl işlendiği açıklamak;
                  </li>
                  <li>
                    Bu bilgilerin hangi amaç ve yöntemlerle üçüncü kişilere
                    aktarıldığı konusunda bilgi vermek;
                  </li>
                  <li>
                    Kişisel verilerinizle ilgili yasal haklarınız konusunda sizi
                    aydınlatmak;
                  </li>
                  <li>
                    Haklarınızı ve mahremiyetinizi koruma konusundaki
                    sorumluluğumuzu açıklamaktır.
                  </li>
                </ul>
                <p>
                  Bu politika yalnızca Eduvery ürün ve hizmetlerinin kullanımı
                  bakımından geçerlidir. Eduvery'ya ait olmayan tüm web sitesi
                  ve ortamlarda, ilgili kişi veya kurumun gizlilik politikası ve
                  kuralları geçerli olacaktır. Bir satıcının web sitesine ödeme
                  bilgilerini Eduvery'ya aktarmak amacıyla Eduvery ödeme
                  formunun yerleştirilmiş olması gibi durumlarda ise hem
                  Eduvery'nun, hem de ilgili satıcının gizlilik politikaları
                  geçerli olacaktır. Eduvery tarafından işlenen kişisel
                  verilerinizin türleri hangi hizmetlerden, nasıl
                  faydalandığınıza göre değişebilir. Bulunduğunuz bölge ve
                  hukuki durumunuz gibi faktörlere bağlı olarak hizmetlerin tümü
                  size açık olmayabilir.
                </p>
                <h3 className="madde-baslik">
                  2. Eduvery Tarafından Toplanan Kişisel Veriler
                </h3>
                <p>
                  Web sitemizi ziyaret ettiğinizde, ya da hizmetlerimizi
                  kullandığınızda sizin hakkınızda çeşitli kişisel veriler
                  toplarız. Web sitemize girdiğinizde, Eduvery aracılığıyla bir
                  ödeme gönderip aldığınızda, ya da Eduvery'ya kaydolup bir
                  hesap açtığınızda; talep ettiğiniz hizmetleri etkili ve
                  güvenli bir biçimde sunmak için gereken bilgileri toplarız. Bu
                  bilgiler aşağıdakileri içerebilir:
                </p>
                <ul>
                  <li>
                    Kişisel bilgileriniz (örneğin isminiz, adresiniz, e-posta
                    adresiniz, kimlik numaranız).
                  </li>
                  <li>
                    Ödeme bilgileri (örneğin kredi kartı numaranız, kartınızın
                    son kullanma tarihi, taksit tercihiniz).
                  </li>
                  <li>
                    Birisi üçüncü kişilere ödeme gönderdiğinde, ya da bize
                    üçüncü kişiler hakkında bilgi vermeleri gereken başka bir
                    işlem yaptığında, bu üçüncü kişiler hakkındaki kişisel
                    verileri elde edebiliriz.
                  </li>
                  <li>
                    Diğer kişilerden elde edilen bilgiler; örneğin Eduvery üyesi
                    bir müşterimizden satın almak istediğiniz ürünlerin veya
                    hizmetlerin isim ve kategorileri, satıcı bünyesindeki
                    üyeliğinize ilişkin detaylar, IP adresiniz ve daha önce
                    yaptığınız işlemlere ilişkin bilgileri elde edebiliriz.
                    Kargo şirketlerinden de siparişinizin durumuna ilişkin
                    bilgileri elde ederek kullanabiliriz.
                  </li>
                  <li>
                    Eduvery hizmetlerinden yararlanan bir müşterimiz olmak
                    istemeniz halinde, iş yerinizin türünü ve kimliğini
                    belirlemeye yarayan bilgiler, iletişim bilgileri, işletmeye
                    ilişkin tanıtıcı ve açıklayıcı bilgi ve belgeler, ödemenin
                    aktarılabilmesi için gereken banka bilgileri ve Eduvery ürün
                    ve hizmetlerini kullanımınıza ilişkin kayıtlar (IP adresi,
                    kullandığınız web altyapısı vs.) elde edilecektir. Bu
                    bilgilerin bir kısmı veya tamamı kişisel veriler içerebilir
                    ve firma olarak, bu kişisel verileri paylaşmaya yetkili
                    olduğunuzu garanti etmeniz gerekir.
                  </li>
                  <li>
                    Eğer bir platform (örneğin bir pazaryeri) bünyesinde satış
                    yapan bir satıcı iseniz, üye olduğunuz pazaryeri tarafından
                    işletmenize ilişkin tanıtıcı bilgiler, iletişim ve banka
                    hesabı bilgileriniz Eduvery'ya aktarılacaktır.
                  </li>
                  <li>
                    Eduvery tarafından sunulan opsiyonel hizmetlerden
                    faydalanmanız, ya da Eduvery ile iletişime geçmeniz gibi
                    durumlarda bize sunduğunuz ek bilgiler Eduvery tarafından
                    toplanmaktadır.
                  </li>
                  <li>
                    Çerezler (cookie), işaretçiler ve diğer takip mekanizmaları
                    üzerinden elde edilen bilgiler (örneğin cihazınıza, coğrafi
                    konumunuza ve kullanımınıza ilişkin detaylar).
                    Hizmetlerimizi sunmak ve ilgi alanınıza dönük pazarlama
                    faaliyetleri için kullandığımız çerezler ve diğer takip
                    teknolojilerinin kullanımı hakkında daha detaylı bilgi için
                    lütfen aşağıdaki 4. Bölümü okuyunuz.
                  </li>
                </ul>
                <h3 className="madde-baslik">
                  3.Kişisel Verilerin Eduvery Tarafından Kullanımı ve Hukuki
                  Dayanaklar
                </h3>
                <p>
                  Elimizdeki kişisel verileri, Türkiye ve Avrupa Birliği'ndeki
                  yasal düzenlemelerin izin verdiği çeşitli amaçlar için
                  kullanırız. Kişisel verilerinizi kullanma amaçlarımız aşağıda
                  örneklerle açıklanmıştır. Bu kişisel verileri işlerken
                  dayandığımız hukuka uygunluk kriterleri; sözleşmesel
                  yükümlülüklerimizin yerine getirilmesi, meşru menfaatlerimiz
                  ve hukuki yükümlülüklerimiz olabilir.
                </p>
                <ul>
                  <li>
                    Web sitemizi ve hizmetlerimizi size sunabilmek: Ödemeleri
                    gerçekleştirebilmek, para transferi yapabilmek, kredi
                    kartlarınızı ilerde kullanabilmeniz için isteğiniz üzerine
                    saklamak ve bir hesap oluşturmanızı, bu hesaba erişmenizi ve
                    üzerinde değişiklik yapabilmenizi sağlamak için kişisel
                    bilgilerinizi kullanmamız gerekir.
                  </li>
                  <li>
                    Hizmetlerimizi iyileştirmek ve işletmesel gerekliliklerimizi
                    yönetebilmek: Web sitelerimizin ve hizmetlerimizin
                    kullanımını gözetleyip, kullanıma ilişkin verileri analiz
                    ederek performansımızı artırırız.
                  </li>
                  <li>
                    Risk yönetimi ve platformumuz ile müşterilerimizi zarardan
                    korumak: Kötü niyetli işlemleri bulup önleyebilmek için
                    müşterilerimizi tanır ve risk seviyelerini ölçeriz. Bu
                    konudaki kararlar belirli kurallar dahilinde otomatik
                    algoritmalar veya bizzat görevlilerimiz tarafından
                    verilebilir. Bu incelemeler sonucunda gerçekleştirmek
                    istediğiniz bir başvuru veya işlem reddedilirse, bu sonuca
                    belgenin sonundaki iletişim bilgileri üzerinden bize
                    ulaşarak bilgi talep edebilirsiniz.
                  </li>
                  <li>
                    Gerekli olduğunda sizinle iletişime geçmek: Yaptığınız
                    işlemlerin durumu hakkında sizi bilgilendirmek için iletişim
                    bilgilerinizi kullanırız. Ayrıca yaptığınız işlemle ya da
                    hesabınızla ilgili bir sorun ortaya çıktığında, konu
                    hakkında size ulaşıp sorunu çözmek için de iletişim
                    bilgilerinizi kullanabiliriz. Sizinle Eduvery adına
                    iletişime geçenler doğrudan Eduvery çalışanları olabileceği
                    gibi, hizmet aldığımız farklı iş ortaklarımız da olabilir,
                    ki bu durumda kişisel verilerinizin bu iş ortaklarıyla
                    paylaşılması gerekir.
                  </li>
                  <li>
                    Yasal yükümlülüklerimizi yerine getirmek veya uyuşmazlıkları
                    çözmek: Faaliyet gösterdiğimiz yerlerdeki bazı düzenlemeler
                    bilgilerinizi belli şekillerde kullanmamızı ve saklamamızı
                    zorunlu kılabilir. Ödeme hizmeti sağlayıcısı olarak aracılık
                    ettiğimiz tüm işlemlerin kayıtlarını veri saklama
                    yükümlülüklerimizi yerine getirmek, para aklamanın önlenmesi
                    ve vergilendirme gibi amaçlarla tutmak ve incelemekle
                    yükümlüyüz.
                  </li>
                </ul>
                <p>
                  Aracılık ettiğimiz bir işlemin tarafları, ya da Eduvery ile
                  kullanıcılar arasında bir uyuşmazlık çıktığında, kişisel
                  bilgileriniz bu uyuşmazlığın çözülmesi için kullanılabilir,
                  mahkemelere ve alternatif çözüm mercilerine aktarılabilir.
                  Bilgilerinizi rıza gösterdiğinizi diğer amaçlar için de
                  kullanabiliriz. Örneğin size pazarlama iletileri göndermek,
                  sizden geribildirim almak gibi diğer işleme faaliyetleri için
                  rızanızı alabiliriz. Böyle durumlarda verdiğiniz rızayı her
                  zaman geri alabilirsiniz, bunun için belgenin sonunda yer alan
                  iletişim bilgilerinden bize ulaşabilirsiniz. Bize ulaşırken
                  sizi tanımamıza yarayacak bilgileri de mesajınıza eklemeniz
                  gerekir. Rızanızı geri alma bildiriminiz geleceğe dönük olarak
                  etkili olur, bu yüzden de geçmişte rızanıza dayanarak
                  gerçekleştirdiğimiz işlemeler hukuka aykırı hale gelmez, ancak
                  bildiriminizi aldıktan sonra ilgili bilgileri işlemeye son
                  veririz.
                </p>
                <h3 className="madde-baslik">
                  4. Çerezlerin ve Diğer Takip Teknolojilerinin Kullanımı
                </h3>
                <p>
                  Eduvery web sitesinin ve hizmetlerinin kullanımı sırasında,
                  internet servis sağlayıcınızın adı, IP adresleri ve
                  gezindiğiniz sayfalar gibi, erişim verileri toplanır. Bu
                  bilgiler Eduvery'nun tabi olduğu ve web sitesine giren web
                  sitesinin alt yapısının sağlanması, istatistiki incelemeler
                  yapılması ve Eduvery ile ilgili tanıtımların daha doğru
                  hedeflere yönlendirilmesi amacıyla kullanılabilir. Ayrıca,
                  kullanımınıza ilişkin bilgiler toplamak ve bu bilgileri site
                  fonksiyonlarını sağlama, site altyapısını yönetme ve pazarlama
                  amaçlarıyla kullanmak üzere Eduvery veya diğer hizmet
                  sağlayıcılara ait çerezler (küçük boyutlu dosyalar) ve
                  işaretçiler kullanılır. Bu site Google Inc. ("Google")
                  tarafından sağlanan bir web analiz hizmeti olan Google
                  Analytics kullanmaktadır. Google Analytics, web sitesi
                  sahibinin, kullanıcıların siteyi nasıl kullandıklarını analiz
                  etmesine yardımcı olmak ve Google'ın da hizmetlerini
                  sürdürmesini sağlamak amacıyla "çerezler" ve diğer kimlik
                  tanımlayıcıları kullanmaktadır. Web sitesini kullanımınızla
                  ilgili üretilen bilgiler (IP adresiniz de dahil olmak üzere)
                  Google'ın farklı ülkelerde bulunabilen sunucularına iletilecek
                  ve buralarda Google tarafından işlenecektir. Google bu
                  bilgileri web sitesini kullanımınızı değerlendirmek, web
                  sitesi sahipleri için web sitesi aktivitesi hakkında raporlar
                  oluşturmak ve web sitesi aktivitesi ve internet kullanımı ile
                  ilgili başka hizmetler sağlamak (örneğin kullanıcı
                  kategorileri oluşturmak) amacıyla kullanacaktır. Google bu
                  bilgileri yasal zorunluluklar ya da üçüncü şahısların bu
                  bilgileri Google adına işlemesi durumlarında üçüncü şahıslara
                  iletebilir. Google Analytics hakkında daha detaylı bilgi almak
                  için şu sayfayı ziyaret edebilirsiniz:
                  <a
                    href="https://www.google.com/policies/privacy/partners/ "
                    target="_blank"
                  >
                    https://www.google.com/policies/privacy/partners/
                  </a>{" "}
                  Google Analytics Hizmetleri için bilgilerinizin kullanılmasını
                  istemiyorsanız, şu linkte yer alan talimatları izleyerek
                  Google Analytics kapsamının dışına çıkmayı talep
                  edebilirsiniz:{" "}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout/"
                    target="_blank"
                  >
                    https://tools.google.com/dlpage/gaoptout/
                  </a>{" "}
                  Bu site Hotjar Ltd. ("Hotjar") tarafından sağlanan ve
                  kullanıcıların web sitesi üzerindeki deneyimlerini tespit
                  ederek web sitesinin kullanım kolaylığını sorgulayan,
                  kullanıcılardan anonim olarak geri bildirim alan bir hizmetten
                  yararlanmaktadır. Hotjar'ın bu hizmeti verebilmesi için
                  bilgisayarınıza çerezler yerleştirilmektedir. Bu site
                  Optimizely Inc. ("Optimizely") tarafından sağlanan ve
                  kullanıcılara web sitesinin farklı versiyonlarını göstererek
                  hangi versiyonun kullanım açısından daha faydalı olduğunu
                  bulmaya yarayan hizmetten yararlanmaktadır. Optimizely'nin bu
                  hizmeti verebilmesi için bilgisayarınıza çerezler
                  yerleştirilmektedir. Bu site ve Eduvery üye iş yeri kontrol
                  paneli DVEB YAZILIM ÇÖZÜMLERİ A.Ş. ("Insider") tarafından
                  sağlanan ve üye iş yerlerine daha önce görüntüleyemedikleri
                  özellikleri bulmalarına destek olan bir hizmetten
                  faydalanmaktadır. Insider'ın bu hizmeti verebilmesi için
                  bilgisayarınıza çerezler yerleştirilmektedir. Bu site Doğan
                  İnternet Yayıncılığı ve Yatırım A.Ş. ("Medyanet") tarafından
                  sağlanan ve kullanıcı davranışlarını analiz eden bir hizmetten
                  faydalanmaktadır. Medyanet'in bu hizmeti verebilmesi için
                  bilgisayarınıza çerezler yerleştirilmektedir. Tarayıcınız
                  üzerinde uygun ayarları seçerek çerezlerin kullanılmasını
                  reddedebilirsiniz, fakat bunu yaptığınız takdirde bu web
                  sitesini tam işlevli bir biçimde kullanamayabileceğinizi
                  lütfen unutmayınız. İnternet sitemizi kullanarak veya
                  hizmetlerimizden faydalanarak kişisel verilerinizin yukarıda
                  açıklanan Google, Hotjar, Optimizely, Insider, Medyanet ve
                  diğer üçüncü şahıs servis sağlayıcılar tarafından işlenmesine
                  onay vermektesiniz.
                </p>
                <h3 className="madde-baslik">
                  5. Kişisel Verilerin Saklanması ve Silinmesi
                </h3>
                <p>
                  Eduvery'nun kişisel verilerinizi kimliğinizin belirlenmesine
                  imkan verecek şekilde saklayacağı süre yasal
                  yükümlülüklerimizin ve işletmesel gerekliliklerimizin
                  karşılanması için gereken en kısa süredir. <br />
                  Kişisel verilerinizin saklanacağı süre sona erdiğinde, kişisel
                  verilerinizi verilerinizin güvenliğini sağlamayı amaçlayan
                  şirket içi prosedürlerimiz uyarınca silerek veya anonim hale
                  getirerek sizinle ilişkilendirilememesini sağlarız. <br />
                  Hesabınızı kapattığınızda veya kişisel verilerinizin
                  silinmesini talep ettiğinizde, bu verilerden yasal
                  düzenlemelere göre hesabınızı kapatsanız bile tutmak zorunda
                  olduğumuz veya silme talebinizi reddettiğimiz veriler dışında
                  kalanları siler veya anonim hale getiririz. <br />
                  Kullandığımız çerezlerin belli geçerlilik süreleri vardır ve
                  bu süre boyunca web sitemizi ya da hizmetlerimizi
                  kullanmazsanız kendiliğinden devre dışı kalırlar ve saklanan
                  veriler silinir. Ayrıca bu çerezleri internet tarayıcınızın
                  ayarlarını kullanarak da silebilirsiniz. <br />
                </p>
                <h3 className="madde-baslik">
                  6. Kişisel Verilerin Üçüncü Kişilere Aktarımı
                </h3>
                Hizmetlerimizi sunarken kimi zaman aşağıda açıklanan amaçlarla
                kişisel verilerinizi üçüncü kişilerle paylaşırız.
                Faaliyetlerimizi yönetmek ve hizmetlerimizi güvenli ve sürekli
                biçimde sunabilmek için kişisel verilerinizi Eduvery grup
                şirketleri arasında paylaşırız. Eduvery grup şirketleri
                arasındaki aktarımlar gerekli önlemler alınarak gerçekleştirilir
                ve kişisel verileriniz bu aktarım nedeniyle bu gizlilik
                politikasının kapsamı dışına çıkmaz. Gerekli durumlarda başka
                şirketlerin hizmetlerinden faydalanırız ve bunlardan bazılarıyla
                kişisel verilerinizi paylaşmamız gerekebilir. Örneğin, ödeme
                bilgilerinizi aldığımızda (bu bilgileri doğrudan sizden veya
                ödeme yapmak istediğiniz satıcı aracılığıyla alabiliriz) ilgili
                finansal kuruluşlara aktararak ödemenizin gerçekleştirilmesini
                sağlarız. Ya da yaptığınız işlem ek adımlar gerektiriyorsa
                (örneğin gümrük işlemlerinin tamamlanmasını gerektiren bir
                uluslararası satışsa), bu adımların atılabilmesini sağlamak için
                ilgili hizmet sağlayıcılarına (örneğin gümrük müşavirlerine)
                verilerinizi aktarabiliriz. Kullanıcılarımızın ve işletmemizin
                güvenliğini ve rahatlığını sağlamak için de üçüncü kişilere
                (örneğin dolandırıcılığı önlemeyi amaçlayan veri işleyicilerine)
                kişisel veri aktarabiliriz. Hizmetlerimizi kullandığınızda,
                yaptığınız işlemin diğer taraflarını (örneğin ödeme yaptığınız
                satıcıyı veya diğer kullanıcıları) bilgilendiririz. Bu
                bilgilendirme kişisel verilerinizin bir kısmının bu kişilere
                aktarılmasını içerebilir. Kolluk kuvvetleri ve diğer yetkili
                kurumlar bizden kişisel verilerinizi talep edebilirler. Bu
                bilgileri yalnızca yasal olarak zorunlu olduğumuz durumlarda ya
                da bir suçun önlenmesi veya araştırılması için gerekli olduğunu
                düşündüğümüzde paylaşırız. Verilerinizi diğer işletmesel amaçlar
                için de üçüncü kişilerle paylaşmamız gerekebilir. Bu işletmesel
                amaçlar şirket denetimlerini, kurumsal yönetimi ve hukuki
                haklarımızı kullanmak gibi çeşitli faaliyetleri kapsar. Bu
                paylaşımları yaparken de her zaman yasal düzenlemelere uygun
                hareket ederiz.
                <h3 className="madde-baslik">
                  {" "}
                  7. Üçüncü Ülkelere Kişisel Veri Aktarımı
                </h3>
                Sunduğumuz hizmetlerin niteliği gereği, bazı kişisel verileri
                uluslararası olarak aktarmamız gerekebilir. Bilgi teknolojisi
                altyapımızın tamamı Türkiye'de yer alır ve bu yüzden, Türkiye
                dışında bir ülkeden web sitemize erişmeniz ya da hizmetlerimizi
                kullanmanız durumunda kişisel verileriniz Türkiye'ye
                aktarılacaktır. Bu aktarım kişisel verilerinizin güvenliğini
                sağlayan yasal bir çerçevede gerçekleşir. Eduvery şirketleri
                arasındaki aktarımlara ek olarak, yukarıda açıklanan üçüncü
                kişilere veri aktarımları, kişisel verilerinizin farklı ülkelere
                aktarılmasını gerektirebilir. Ancak her durumda kişisel
                verilerinizin, yalnızca yeterli korumayı sağlayan ülkelere, ya
                da bu ülkeler dışında yer alan ancak verilerinizin güvenliğini
                düzenlemelere uygun olarak sağlayacağını taahhüt eden kişilere
                aktarılacağını garanti ederiz.
                <h3 className="madde-baslik">8. Sizin Tercihleriniz</h3>
                Gizliliğinize ilişkin konularda bazı tercihlerde
                bulunabilirsiniz. Eduvery tarafından talep edilen kişisel
                verilerinizi vermeyi her zaman reddedebilirsiniz. Ancak, vermeyi
                reddettiğiniz bilgilerin hizmetlerimizi sunabilmemiz için
                zorunlu olduğu durumlarda, hizmetlerimizden faydalanamazsınız.
                Örneğin, Eduvery aracılığıyla kredi kartlı ödemeleri kabul eden
                bir satıcının ödeme sayfasında kredi kartı numaranızı
                girmezseniz, ödemeyi gerçekleştirmemiz mümkün olmaz. 4. Bölüm'de
                açıkladığımız gibi, hizmetlerimizi daha iyi sunabilmek için
                çerezlerden de faydalanırız. Bu çerezleri reddetmek için
                internet tarayıcınızın ayarlarını kullanabilirsiniz, ancak bu
                çerezleri reddetmeniz halinde web sitemizi ve hizmetlerimizi
                kullanamayabilirsiniz. Elimizde bulunan kişisel verilerinizin
                bir kısmını Eduvery mobil veya web uygulamasına giriş yaptıktan
                sonra ayarlar bölümünde görebilirsiniz. Bunlar veya bunların
                dışında kalan kişisel verileriniz üzerinde sahip olduğunuz
                hakları kullanmak isterseniz, belgenin sonunda yer alan iletişim
                bilgileri üzerinden bize ulaşabilirsiniz. Zaman zaman sizinle
                iletişime geçeriz. Bu iletişim pazarlama amaçlı veya tarafı
                olduğunuz işlemlere ilişkin olabilir. Pazarlamayla ilgili tüm
                iletileri almayı reddedebilirsiniz, bunun için size gönderilen
                iletilerin en alt kısmında yer alan talimatları takip
                edebilirsiniz. Yapılan işlemlere ilişkin bilgilendirme
                mesajlarını almayı reddedemezsiniz, ancak bunların gönderildiği
                yöntem ve adresleri değiştirmeniz mümkün olabilir.
                <h3 className="madde-baslik">9. Haklarınız</h3>
                Yasal düzenlemeler doğrultusunda aşağıda belirtilen haklara
                sahip olduğunuzu belirtmek isteriz: (a) Kişisel verilerinizin
                işlenip işlenmediğini, işlenen kişisel verileri, kişisel
                verilerin edinildiği kaynakları, yurtiçinde ve yurtdışında
                aktarıldığı üçüncü kişileri, verilerin saklandığı süre veya
                sürenin belirlenmesinde kullanılan kıstasları öğrenme; (b)
                Kişisel verilerinize erişerek, kendi sağladığı kişisel
                verilerin, yapılandırılmış, yaygın olarak kullanılan ve makinece
                okunur bir formatta kendisine verilmesini veya başka bir veri
                sorumlusuna aktarılmasını talep etme, (c) Kişisel verilerinizin
                işlenme amacını ve bunların amacına uygun kullanılıp
                kullanılmadığını öğrenme, (d) Kişisel verilerinizin işlenmesine
                itiraz etme veya kısıtlanmasını talep etme, (e) Kişisel
                verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların
                düzeltilmesini isteme, (f) İşlenmesini gerektiren sebeplerin
                ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya
                yok edilmesini isteme, (g) Düzeltme ve silme gibi işlemlerin,
                kişisel verilerinizin aktarıldığı üçüncü kişilere de
                bildirilmesini isteme, (h) İşlenen verilerin otomatik sistemler
                aracılığıyla analiz edilmesi suretiyle aleyhte bir sonucun
                ortaya çıkmasına itiraz etme, (i) Kişisel verilerin kanuna
                aykırı olarak işlenmesi sebebiyle zarara uğranması hâlinde
                zararın giderilmesini talep etme. Yukarıda belirtilen kişisel
                verilerinize ilişkin haklarınızı belgenin sonundaki iletişim
                bilgileri üzerinden bize ulaşarak kullanabilirsiniz. Bu
                başvuruyu yaparken sizi tanıyabilmemiz için, kayıt olurken ya da
                hizmetlerimizden yararlanırken verdiğiniz bilgilerinizi tam
                olarak belirtmeniz ve resmi bir kimliğin kopyasını eklemeniz
                gerekir.
                <h3 className="madde-baslik">
                  10. Kişisel Verilerin Korunması
                </h3>
                Eduvery tarafından elde edilen tüm kişisel veriler, sektörde
                kabul görmüş makul güvenlik şartlarına tabi olarak iletilmekte
                ve saklanmaktadır. Ödeme verilerinin güvenliği PCI DSS (Ödeme
                Sektörü Veri Güvenliği Standartları) Level 1 uyumlu altyapımızla
                sağlanmaktadır ve kişisel verilerin aktarıldığı bağlantılar
                şifrelenerek korunmaktadır. Kişisel bilgilerinizi korumak için
                tüm makul önlemleri alsak da, şifrenizin ve hesap bilgilerinizin
                güvenliğini sağlamak sizin sorumluluğunuzdadır.
                <h3 className="madde-baslik">11. Diğer Bilgiler</h3>
                Hizmetlerimiz ve web sitemiz çocukların (yasal olarak yetişkin
                olmayan tüm kişilerin) kullanımına yönelik değildir. Çocuklardan
                ve hukuki olarak hizmetlerimizden yararlanamayacak kişilerden
                kişisel veri toplamayız. Bir çocuğa ait kişisel verileri elde
                ettiğimizi anladığımızda, bu veriyi saklamamız yasal olarak
                zorunlu değilse derhal sileriz. Bir hata ya da ihmal sonucu bir
                çocuğa ait kişisel verileri elde ettiğimizi düşünüyorsanız,
                lütfen aşağıdaki iletişim bilgileri üzerinden bize hemen ulaşın.
                Bu gizlilik politikasında değişiklikler yapabiliriz. Yapılan
                değişiklikler yeni gizlilik politikasının geçerli olmak üzere
                yayınlandığı tarihten itibaren etkili olacaktır. Kullanıcılar ve
                müşterilerimiz muhtemel güncellemeleri takip etmek ve işlem
                yaptıkları, ya da kendileriyle işlem yapan kişilerin bilgilerini
                Eduvery'ya aktarmadan önce bu kişileri Eduvery'nun veri işleme
                faaliyetlerinden ve bu gizlilik politikasından haberdar etmekle
                yükümlüdür.
                <h3 className="madde-baslik">12. İletişim Bilgileri</h3>
                Şirket Unvanı: DveB Yazılım Çözümleri A.Ş. <br />
                Adres: Küçükbakkalköy Mah. Mor Orkide Sokak, No: 3
                Ataşehir/İstanbul
                <br />
                E-Posta: satis@dveb.net
                <br />
                Telefon: +90 541 784 88 64
                <br />
              </article>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

export default Gizlilik;
