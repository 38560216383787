import React, { useState } from "react";
import QuestionsHeader from "../Layout/QuestionsHeader";
import { useQuestionContext } from "../../context/QuestionContext";

const CheckImageChoiceQuestion = ({ data }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const { activeStep, nextQuestionRequest } = useQuestionContext();
  const questionFinish = () => {
    const datas = {
      question:activeStep + 1,
      answer:1,
      point:data.rightAnswer === selectedAnswer ? 10 : 0
    }
    nextQuestionRequest(datas)
  }

  return (
    <div className="before-questions">
      <QuestionsHeader />

      <div className="container ">
        <div className="question-area">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <p className="question-q">
              <span>{activeStep + 1}.</span> {data.question}
            </p>
            <div className="row justify-content-center">
              <div className="col-lg-10 col-12">
                <div className="d-flex flex-wrap justify-content-center gap-4   w-100 mt-5">
                  {data.answers.map((answer, key) => (
                    <div className="  mb-2" key={key}>
                      <div className="form-check ps-0">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                          name="flexRadioDefault"
                          id={answer.id}
                          onChange={() => setSelectedAnswer(answer.id)}
                        />
                        <label
                          className="form-check-label answers-check answers-check-image answer-checkbox"
                          htmlFor={answer.id}
                        >
                          <img
                            src={`./images/question-eleven/${answer.answer}`}
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center w-100 mt-2 question-fixed-bottom">
              <button
                className={`button-shadow ${
                  !selectedAnswer ? "shadow-gray" : "shadow-blue"
                }`}
                disabled={!selectedAnswer}
                onClick={questionFinish}
              >
                İlerle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckImageChoiceQuestion;
