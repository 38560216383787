import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

const QuestionLayout = () => {
  const {userData} = useSelector(state => state.user)
  const navigate = useNavigate() 
  useEffect(() => {
    if(Object.keys(userData).length === 0){
        navigate('/login')
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Outlet />
  )
}

export default QuestionLayout