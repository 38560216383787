import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

const EmptyLayout = () => {
  const {userData} = useSelector(state => state.user)
  const navigate = useNavigate()
  useEffect(() => {
    if(userData?.id){
        navigate("/profile")
    }
  },[userData])
  return (
    <Outlet />
  )
}

export default EmptyLayout