import React, { Fragment, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useQuestionContext } from "../../context/QuestionContext";
import QuestionsHeader from "../Layout/QuestionsHeader";

const DrawRoute = ({ data }) => {
  const [startQuestion, setStartQuestion] = useState(false);
  const [droppers, setDroppers] = useState(data.drops);
  const [drags, setDrags] = useState(data.drags);
  const rightDirection = ["left","right","left","right","left","right","right"]
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if(destination === null) return false
    if (
      source?.droppableId === "drop-two" &&
      destination?.droppableId !== "drop-two"
    ) {
      const selectedDirection = drags[source.index];
      const selectedDropper = droppers.find(
        (drop) => drop?.id === destination?.droppableId
      );
      if (selectedDropper?.value) {
        const oldDropper = selectedDropper.value;
        let newDrags = drags.filter((drag) => drag?.id !== selectedDirection?.id);
        newDrags.push(oldDropper);
        setDrags(newDrags);
        const newDroppers = droppers.map((droppers) =>
          droppers?.id === selectedDropper?.id
            ? { ...droppers, value: selectedDirection }
            : droppers
        );

        setDroppers(newDroppers);
      } else {
        const newDroppers = droppers.map((droppers) =>
          droppers?.id === selectedDropper?.id
            ? { ...droppers, value: selectedDirection }
            : droppers
        );
        const newDrags = drags.filter(
          (drag) => drag?.id !== selectedDirection?.id
        );
        setDroppers(newDroppers);
        setDrags(newDrags);
      }
    } else {
      if (
        destination?.droppableId === "drop-two" &&
        source?.droppableId !== "drop-two"
      ) {
        const selectedDirection = droppers[source.index];
        const newDroppers = droppers.map((drop) =>
          drop?.id === selectedDirection?.id ? { ...drop, value: "" } : drop
        );
        setDrags([...drags, selectedDirection.value]);
        setDroppers(newDroppers);
      }
    }
  };
  const {  activeStep,nextQuestionRequest } = useQuestionContext();
  const handleActive = () => {
    if (!startQuestion) {
      setStartQuestion(true);
    } else {
      let totalRight = 0
      droppers.forEach((element,key) => {
        if(element.value.direction === rightDirection[key]){
          totalRight++
        }
      })
      const datas = {
        question:activeStep + 1,
        answer:1,
        point:totalRight === 7 ? 10 : 0,
      }
      nextQuestionRequest(datas)
    }
  };
  return (
    <div className="before-questions">
      <QuestionsHeader />
      <div className="container ">
        <div className="question-area">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <div className="row ">
              <div className="col-6">
                <div className="h-100 d-flex align-items-center flex-column justify-content-center">
                  <p className="question-q">
                    <span>{activeStep + 1}. </span>
                    Ali,Evinde istirahatte olan arkadaşı Ahmet'i ziyaret etmek
                    istemektedir. Ali, yanda verilen krokide A noktasındaki
                    çiçekçiden bir buket çicek alıp B noktasındaki Ahmet'in
                    evine gidecektir. Ali hangi yönergeleri izlemelidir.
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="image-kroki">
                  <img src="./images/question-seven/map-three.png" alt="kriko" />
                </div>
              </div>
              <div className="col-12 mt-5">
                {startQuestion && (
                  <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                    <div className="d-flex w-100 justify-content-between align-items-center mt-5 mb-5">
                      <div className="d-flex flex-column align-items-center">
                        <div className="box-triagle">A</div>
                        <h6 className="mb-0">Başlangıç</h6>
                      </div>
                      {droppers.map((drop, key) => (
                        <Fragment key={key}>
                          <Droppable
                            droppableId={drop.id}
                            direction="horizontal"
                            alignContent="center"
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className={`number-dropper number-dropper-lg ${
                                    snapshot.isDraggingOver ? "active" : ""
                                  }   `}
                                >
                                  {drop.value && (
                                    <Draggable
                                      draggableId={`drag-${drop.value.id}`}
                                      index={key}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`symbol symbol-router   ${
                                              snapshot.isDragging
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {drop.value.direction ===
                                            "right" ? (
                                              <Fragment>
                                                <span className="symbol-arrow">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="53"
                                                    height="37"
                                                    viewBox="0 0 53 37"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M36.2345 1.47744C34.979 0.202728 32.8095 1.0917 32.8095 2.88085V8.8125C32.8095 9.91707 31.9141 10.8125 30.8095 10.8125H2.52381C1.13067 10.8125 0 11.9605 0 13.375V23.625C0 25.0395 1.13067 26.1875 2.52381 26.1875H30.8095C31.9141 26.1875 32.8095 27.0829 32.8095 28.1875V34.1191C32.8095 35.9083 34.979 36.7973 36.2345 35.5226L51.6178 19.9034C52.3845 19.1249 52.3845 17.8751 51.6178 17.0966L36.2345 1.47744Z"
                                                      fill="#3B4EFF"
                                                    />
                                                  </svg>
                                                </span>

                                                <p className="symbol-text text-center">
                                                  Sağ İleri
                                                </p>
                                              </Fragment>
                                            ) : (
                                              <Fragment>
                                                <span className="symbol-arrow">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="53"
                                                    height="37"
                                                    viewBox="0 0 53 37"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M16.7655 35.5226C18.021 36.7973 20.1905 35.9083 20.1905 34.1191L20.1905 28.1875C20.1905 27.0829 21.0859 26.1875 22.1905 26.1875L50.4762 26.1875C51.8693 26.1875 53 25.0395 53 23.625L53 13.375C53 11.9605 51.8693 10.8125 50.4762 10.8125L22.1905 10.8125C21.0859 10.8125 20.1905 9.91707 20.1905 8.8125L20.1905 2.88085C20.1905 1.09169 18.021 0.202721 16.7656 1.47743L1.38223 17.0966C0.615473 17.8751 0.615469 19.1249 1.38223 19.9034L16.7655 35.5226Z"
                                                      fill="#3B4EFF"
                                                    />
                                                  </svg>
                                                </span>

                                                <p className="symbol-text text-center">
                                                  Sol İleri
                                                </p>
                                              </Fragment>
                                            )}
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  )}
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                          <div>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="31"
                                viewBox="0 0 30 31"
                                fill="none"
                              >
                                <path
                                  d="M5 15.6543H25M25 15.6543L17.5 8.1543M25 15.6543L17.5 23.1543"
                                  stroke="#222222"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{" "}
                            </span>
                          </div>
                        </Fragment>
                      ))}

                      <div className="d-flex flex-column align-items-center">
                        <div className="box-triagle">B</div>

                        <h6 className="mb-0">Bitiş</h6>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between align-items-center mt-3">
                      <Droppable
                        droppableId={"drop-two"}
                        direction="horizontal"
                        alignContent="center"
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className={`d-flex justify-content-center align-items-center gap-3 droppable-area w-100 ${
                                snapshot.isDraggingOver ? "active" : ""
                              }`}
                            >
                              {drags.map((drag, key) => (
                                <Draggable
                                  key={key}
                                  draggableId={`drag-${key}`}
                                  index={key}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`symbol symbol-router ${
                                          snapshot.isDragging ? "active" : ""
                                        }`}
                                      >
                                        {drag.direction === "right" ? (
                                          <Fragment>
                                            <span className="symbol-arrow">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="53"
                                                height="37"
                                                viewBox="0 0 53 37"
                                                fill="none"
                                              >
                                                <path
                                                  d="M36.2345 1.47744C34.979 0.202728 32.8095 1.0917 32.8095 2.88085V8.8125C32.8095 9.91707 31.9141 10.8125 30.8095 10.8125H2.52381C1.13067 10.8125 0 11.9605 0 13.375V23.625C0 25.0395 1.13067 26.1875 2.52381 26.1875H30.8095C31.9141 26.1875 32.8095 27.0829 32.8095 28.1875V34.1191C32.8095 35.9083 34.979 36.7973 36.2345 35.5226L51.6178 19.9034C52.3845 19.1249 52.3845 17.8751 51.6178 17.0966L36.2345 1.47744Z"
                                                  fill="#3B4EFF"
                                                />
                                              </svg>
                                            </span>

                                            <p className="symbol-text text-center">
                                              Sağ İleri
                                            </p>
                                          </Fragment>
                                        ) : (
                                          <Fragment>
                                            <span className="symbol-arrow">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="53"
                                                height="37"
                                                viewBox="0 0 53 37"
                                                fill="none"
                                              >
                                                <path
                                                  d="M16.7655 35.5226C18.021 36.7973 20.1905 35.9083 20.1905 34.1191L20.1905 28.1875C20.1905 27.0829 21.0859 26.1875 22.1905 26.1875L50.4762 26.1875C51.8693 26.1875 53 25.0395 53 23.625L53 13.375C53 11.9605 51.8693 10.8125 50.4762 10.8125L22.1905 10.8125C21.0859 10.8125 20.1905 9.91707 20.1905 8.8125L20.1905 2.88085C20.1905 1.09169 18.021 0.202721 16.7656 1.47743L1.38223 17.0966C0.615473 17.8751 0.615469 19.1249 1.38223 19.9034L16.7655 35.5226Z"
                                                  fill="#3B4EFF"
                                                />
                                              </svg>
                                            </span>

                                            <p className="symbol-text text-center">
                                              Sol İleri
                                            </p>
                                          </Fragment>
                                        )}
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </DragDropContext>
                )}
              </div>
              <div className="col-12 mt-5">
                <div className="d-flex justify-content-center question-fixed-bottom">
                  <button
                    className="button-shadow shadow-blue"
                    onClick={() => handleActive()}
                    
                  >
                    {!startQuestion ? "BAŞLAT" : "İLERLE"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawRoute;
