import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { useApi } from "../hooks/useApi";

const jwtToken = localStorage.getItem("edu_user_t");

export const startQuiz = createAsyncThunk("startQuiz", async () => {
  const response = useApi().get("quiz/new")
  const responseData = JSON.parse(response.data)
  return responseData

});
export const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: jwtToken ? jwtDecode(jwtToken) : {},
    quiz_id: 0,
  },
  reducers: {
    handleLogin: (state, action) => {
      const token = jwtDecode(action.payload);
      localStorage.setItem("userData", JSON.stringify(token));
      localStorage.setItem("edu_user_t", action.payload);
      state.userData = token;
      window.location.href = "/profile";
    },
    handleLogout: (state) => {
      state.userData = {};
      localStorage.removeItem("userData");
      localStorage.removeItem("edu_user_t");
      window.location.href = "/";
    },
    handleStartQuizChangeState: (state,action) => {
      state.quiz_id = action.payload
    }
  },
  

  
});

export const { handleLogin, handleLogout,handleStartQuizChangeState } = userSlice.actions;

export default userSlice.reducer;
