import React, { useRef, useState } from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import "../assets/scss/eduvery.scss";
import { Link } from "react-router-dom";
import "react-rangeslider/lib/index.css";
import Slider from "react-rangeslider";
import Contact from "../components/Home/Contact";
import { useSelector } from "react-redux";

const Eduvery = () => {
  const [slider, setSlider] = useState(940);
  const { userData } = useSelector((state) => state.user);
  const howWorkRef = useRef();
  return (
    <div className="fixed-page-wrapper">
      <div className="main-wrapper position-relative">
        <Container className="h-100">
          <Row className="h-100 position-relative">
            <Col md={6} className="h-100">
              <div className="d-flex flex-column justify-content-center h-100">
                <div className="d-flex align-items-center gap-3 mb-5">
                  <img src="./images/edutest-white.svg" alt="edutest" />
                </div>
                <h2 className="page-title">
                  Kendinizi keşfederek geleceğinizi inşa edebileceğiniz yol
                  haritası; Edutest!
                </h2>
                <p className="page-text">
                  Geleceğinizi şekillendirecek en doğru rehber olan Edutest,
                  size hedeflerinize ulaşmak için en iyi yolu gösterir. Bu test
                  sayesinde kendinize yatırım yaparak, istediğiniz noktaya daha
                  hızlı ilerleyebilir, geleceğiniz için doğru bir adım atarak,
                  kariyerinizde bir adım öne geçebilirsiniz.{" "}
                </p>
                <div className="mt-4">
                  <Link to={"/before-questions"} className="button-shadow shadow-blue">
                    BAŞLAYIN
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div
          className="absolute-content"
          onClick={() => {
            howWorkRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          <p className="text-center">Daha Fazlasını Öğren</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_272_9714)">
                <path
                  d="M25 4L12 15L-1 4"
                  stroke="white"
                  strokeWidth="3.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_272_9714">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
      </div>
      <Container>
        <section className="how-it-works" ref={howWorkRef}>
          <div style={{ height: "10rem" }} />

          <Row>
            <Col md="4" className="mb-lg-0 mb-3">
              <div className="d-flex flex-column justify-content-center h-100">
                <h3 className="main-title">Nasıl çalışır</h3>
                <p>
                  Basit ve anlaşılabilir işlemlerimizi takip ederek Edutest’e
                  ulaşabilir ve sana özel yol haritası çıkarmamızı
                  sağlayabilirsin. Adımlarımızı izle ve testi çöz.
                </p>
                <div className="mt-4 ">
                  <Link
                    to={"/register"}
                    className="button-shadow shadow-blue d-lg-inline-block d-block"
                  >
                    BAŞLAYIN
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="7" className="offset-lg-1">
              <div className="work-content d-flex align-items-center  gap-3">
                <div className="number">1</div>
                <div>
                  <h6 className="work-content-title">
                    Test için gereken ödemeyi yap.
                  </h6>
                  <p className="work-content-text">
                    Teste başlayabilmek için sistemden ödeme yap.
                  </p>
                </div>
              </div>
              <div className="work-content d-flex align-items-center  gap-3">
                <div className="number">2</div>
                <div>
                  <h6 className="work-content-title">
                    Sana özel sorular ile interaktif testini çöz.
                  </h6>
                  <p className="work-content-text">
                    Sana özel hazırlanmış olan testi çöz.
                  </p>
                </div>
              </div>
              <div className="work-content d-flex align-items-center  gap-3">
                <div className="number">3</div>
                <div>
                  <h6 className="work-content-title">
                    Eduvery’den çıkan sonuçlar ile geleceğini şekillendir.
                  </h6>
                  <p className="work-content-text">
                    Sana özel raporlanan sonuçları değerlendirerek, geleceğin
                    için en doğru tercihleri yap.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="offer-request">
          <Row>
            <Col xs="12">
              <div className="d-flex align-items-center flex-column">
                <div className="offer-texts col-lg-5 col-12 mb-5">
                  <h2 className="text-center offer-title">
                    {" "}
                    Kaç kişilik bir ekipsiniz?{" "}
                  </h2>
                  <p className="text-center">
                    Ekibinin sayısını belirle ve bizimle iletişime geç.{" "}
                  </p>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="ranger d-flex flex-lg-row flex-column align-items-center w-100 mb-lg-0 mb-4">
                    <h5 className="ranger-text ">Öğrenciler</h5>
                    <div className="col-lg col-12">
                      <Slider
                        min={10}
                        max={1000}
                        step={10}
                        value={slider}
                        onChange={setSlider}
                        className="col-lg col-12"
                      />
                    </div>
                    <h5 className="ranger-text col-lg-3">{slider} Öğrenci</h5>
                  </div>
                  <p className="text-center">Bizimle İletişime Geç</p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to={
                        Object.keys(userData).length > 0 ? "/request" : "/login"
                      }
                      className="button-shadow shadow-blue "
                    >
                      Talep Gönder
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="contact">
          <Contact />
        </section>
      </Container>
    </div>
  );
};

export default Eduvery;
