import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { useQuestionContext } from "../../context/QuestionContext";
import QuestionsHeader from "../Layout/QuestionsHeader";

const mathData = [
  { id: uuidv4(), value: "X" },
  { id: uuidv4(), value: "+" },
  { id: uuidv4(), value: "-" },
  { id: uuidv4(), value: "/" },

];

const process = (firstElement, lastElement, pro) => {
  firstElement = parseInt(firstElement);
  lastElement = parseInt(lastElement);
  let value = 0;
  switch (pro) {
    case "X":
      value = firstElement * lastElement;
      break;
    case "+":
      value = firstElement + lastElement;
      break;
    case "-":
      value = firstElement - lastElement;
      break;
    case "/":
      value = firstElement / lastElement;
      break;
    default:
      break;
  }
  return parseInt(value);
};

const onDragEnd = (
  result,
  math,
  setMath,
  problems,
  setProblems,
  selectedDropLength,
  setSelectedDropLength
) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId === "drop-two") {
    if (destination.droppableId !== "drop-two") {
      const selectedMath = math.find((item, index) => index === source.index);
      const selectedDrop = problems.findIndex(
        (prob) => prob.id === destination.droppableId
      );
      let newProblems = problems;

      const updatedMathData = math.filter(
        (item, index) => index !== source.index
      );
      const updateProblems = newProblems.map((item) =>
        item.id === destination.droppableId
          ? { ...item, value: selectedMath.value }
          : item
      );
      setProblems(updateProblems);
      setSelectedDropLength(selectedDropLength + 1);
    }
  } else {

    const selectedDrop = problems.findIndex(
      (prob) => prob.id === source.droppableId
    );
    let newProblems = problems;

    const firstResultFind = problems.slice(selectedDrop + 1);
    const firstResult = firstResultFind.find((item) => item.type === "result");
    const updateResult = problems.map((item) =>
      item.id === firstResult?.id ? { ...item, value: "" } : item
    );
    newProblems = updateResult;
    const updateProblems = newProblems.map((item) =>
      item.id === source.droppableId ? { ...item, value: "" } : item
    );
    setProblems(updateProblems);
    setSelectedDropLength(selectedDropLength - 1);
  }
};

function ProblemSolving({ data }) {
  const [problems, setProblems] = useState(data.problem);
  const [math, setMath] = useState(mathData);
  const [selectedDropLength, setSelectedDropLength] = useState(0);
  const { nextQuestionRequest } = useQuestionContext();
  const [showFinish, setShowFinish] = useState(false);
  const [answerWrong, setAnswerWrong] = useState(false);
  useEffect(() => {
    const drops = problems.filter((p) => p.type === "drop");
    let allRight = true;
    let allValid = true
    drops.forEach((drop) => {
      if(drop.value === ""){
        allValid=false
      }
    })
    
    
    if (allValid) {
      drops.map((drop) => {
        let isInclude = drop.rightValue.includes(drop.value);
        if (!isInclude) {
          allRight = false;
        }
      });
      setShowFinish(true);
      if (!allRight) {
        setAnswerWrong(true);
      } else {
        setAnswerWrong(false);
      }
    }else{
      if(showFinish){
        setShowFinish(false)
      }
    }
  }, [selectedDropLength]);
  const questionFinish = () => {
    const datas = {
      question:2,
      answer:1,
      point: answerWrong ? 0 : 10
    }
    nextQuestionRequest(datas)
  }
  return (
    <div className="before-questions">
      <QuestionsHeader />

      <div className="container">
        <div className="question-area">
          <div className=" d-flex flex-column align-items-center justify-content-center h-100">
            <p className="question-q mb-5">
              2) Aşağıda, dört aşamadan oluşan bir dizi işlem bulunmaktadır. Her
              aşamada (?) yerine dört işlem sembollerinden birini koyarak
              sonraki adıma geçin ve son işlemin sonucu 11 olacak şekilde
              zinciri tamamlayın.
            </p>
            <div className="d-flex  align-items-center justify-content-center">
              <div className="">
                <DragDropContext
                  onDragEnd={(result) =>
                    onDragEnd(
                      result,
                      math,
                      setMath,
                      problems,
                      setProblems,
                      selectedDropLength,
                      setSelectedDropLength
                    )
                  }
                >
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    {problems.map((prob, key) => {
                      if (prob.type === "number") {
                        return (
                          <div key={prob.id} className="number">
                            {prob.value}
                          </div>
                        );
                      }
                      if (prob.type === "drop") {
                        return (
                          <Droppable
                            key={prob.id}
                            droppableId={prob.id}
                            direction="horizontal"
                            alignContent="center"
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className={`number-dropper ${
                                    snapshot.isDraggingOver ? "active" : ""
                                  }  `}
                                >
                                  {prob.value ? (
                                    <div>
                                      <Draggable
                                        draggableId={prob.id}
                                        index={key}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className={`symbol ${
                                                snapshot.isDragging
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              {prob.value}
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    </div>
                                  ): <span>?</span>}
                                  
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        );
                      }
                      if (prob.type === "equal") {
                        return (
                          <div key={prob.id} className="number">
                            =
                          </div>
                        );
                      }
                      if (prob.type === "result") {
                        return (
                          <div key={prob.id} className="number">
                            {prob.value ? prob.value : "..."}
                          </div>
                        );
                      }
                      if (prob.type === "final") {
                        return (
                          <div key={prob.id} className="number">
                            {prob.value}
                          </div>
                        );
                      }
                    })}
                  </div>

                  <div className="mt-5">
                    <Droppable
                      droppableId={"drop-two"}
                      direction="horizontal"
                      alignContent="center"
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={`d-flex justify-content-center align-items-center gap-3 droppable-area ${
                              snapshot.isDraggingOver ? "active" : ""
                            }`}
                          >
                            {math.map((m, key) => (
                              <Draggable
                                key={key}
                                draggableId={`drag-${key}`}
                                index={key}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`symbol ${
                                        snapshot.isDragging ? "active" : ""
                                      }`}
                                    >
                                      {m.value}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </DragDropContext>
                <div className="d-flex justify-content-center w-100 question-fixed-bottom">
                  <button
                    className={`button-shadow ${
                      !showFinish ? "shadow-gray" : "shadow-blue"
                    }`}
                    onClick={questionFinish}
                    disabled={!showFinish}
                  >
                    İlerle
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProblemSolving;
