import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { useSelector } from "react-redux";

const PrivateLayout = () => {
  const { pathname } = useLocation();
  const {userData} = useSelector(state => state.user)
  const navigate = useNavigate() 
  useEffect(() => {
    if(Object.keys(userData).length === 0){
        navigate('/login')
    }
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className="page-wrapper">
      <Header />
        <Outlet />
      <Footer />
    </div>
  );
};

export default PrivateLayout;
