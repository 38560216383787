import React, { useState } from "react";
import QuestionsHeader from "../Layout/QuestionsHeader";
import { useQuestionContext } from "../../context/QuestionContext";

const PasswordGenerate = ({ data }) => {
  const { activeStep, nextQuestionRequest } = useQuestionContext();
  const [areas, setAreas] = useState([
    {
      id: 1,
      value: {},
    },
    {
      id: 2,
      value: {},
    },
    {
      id: 3,
      value: {},
    },
    {
      id: 4,
      value: {},
    },
    {
      id: 5,
      value: {},
    },
    {
      id: 6,
      value: {},
    },
    {
      id: 7,
      value: {},
    },
    {
      id: 8,
      value: {},
    },
  ]);
  const handleClickElement = (value) => {
    const updatedAreas = [...areas];

    const firstEmptyIndex = updatedAreas.findIndex(
      (area) => Object.keys(area.value).length === 0
    );

    if (firstEmptyIndex !== -1) {
      updatedAreas[firstEmptyIndex] = {
        ...updatedAreas[firstEmptyIndex],
        value: value,
      };

      setAreas(updatedAreas);
    }
  };
  const handleAreaClick = (index) => {
    const updatedAreas = [...areas];
    updatedAreas[index] = {
      ...updatedAreas[index],
      value: {},
    };

    setAreas(updatedAreas);
  };
  const isAllValuesFilled = () => {
    return areas.every((area) => Object.keys(area.value).length !== 0);
  };
  const questionFinish = () => {
    let string = ""
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{4,}$/;

    areas.forEach(area => {
      string += area.value.name
    })
    
    const datas = {
      question:activeStep + 1,
      answer:1,
      point:regex.test(string) ? 10 : 0
    }
    nextQuestionRequest(datas)
  }

  return (
    <div className="before-questions">
      <QuestionsHeader />
      <div className="container">
        <div className="question-area">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <p className="question-q">
              <span>{activeStep + 1}. </span>
              {data.question}
            </p>
            <div className="row justify-content-center gap15 mb6 mt-5">
              {areas.map((area, key) => (
                <div
                  onClick={() => handleAreaClick(key)}
                  key={key}
                  className="number-dropper number-dropper-lg password-numbers-dropper"
                >
                  {area.value?.name}
                </div>
              ))}
            </div>
            <div className="row justify-content-center">
              <div className="col-12 mb-5 ">
                <div className="d-flex justify-content-center align-items-end gap-4">
                  {data.data.alphabet.map((alpha, key) => {
                    return (
                      <span
                        className="password-button"
                        onClick={() => handleClickElement(alpha)}
                        key={key}
                        dangerouslySetInnerHTML={{ __html: alpha.icon }}
                      ></span>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="d-flex justify-content-center align-items-end gap-4">
                  {data.data.numbers.map((alpha, key) => {
                    return (
                      <span
                        className="password-button"
                        key={key}
                        onClick={() => handleClickElement(alpha)}
                        dangerouslySetInnerHTML={{ __html: alpha.icon }}
                      ></span>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="d-flex justify-content-center align-items-end gap-4">
                  {data.data.symbols.map((alpha, key) => {
                    return (
                      <span
                        className="password-button"
                        key={key}
                        onClick={() => handleClickElement(alpha)}
                        dangerouslySetInnerHTML={{ __html: alpha.icon }}
                      ></span>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex justify-content-center w-100  question-fixed-bottom">
              <button
                className={`button-shadow ${
                  !isAllValuesFilled() ? "shadow-gray" : "shadow-blue"
                }`}
                disabled={!isAllValuesFilled()}
                onClick={questionFinish}
              >
                İlerle
              </button>
            </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordGenerate;
