import React, { useState } from "react";
import QuestionsHeader from "../Layout/QuestionsHeader";
import { useQuestionContext } from "../../context/QuestionContext";

const GridImageContext = ({ data }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const { activeStep, nextQuestionRequest } = useQuestionContext();
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const questionFinish = () => {
    const datas = {
      question:activeStep + 1,
      answer:1,
      point:data.rightAnswer === selectedAnswer ? 10 : 0
    }
    nextQuestionRequest(datas)
  }
  return (
    <div className="before-questions">
      <QuestionsHeader />

      <div className="container ">
        <div className="question-area">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <p className="question-q mb-4">
              <span>{activeStep + 1}.</span> {data.question}
            </p>
            <div className="grid-area row  w-100 mt-5">
              <div className="grid-question d-flex align-items-center justify-content-center col-4">
                <div className="">
                  <img
                    src={`./images/question-ten/${data.picture}`}
                    alt=""
                    className="main-image"
                  />
                </div>
              </div>
              <div className="col-8">
              <div className="row  gap-4">
                {data.answers.map((answer, key) => (
                  <div
                    className="col-2 d-flex justify-content-center gap-2 mb-2"
                    key={key}
                  >
                    <span className="shk">{alphabet[key]}.</span>
                    <div className="form-check ps-0">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="flexRadioDefault"
                        id={answer.id}
                        onChange={() => setSelectedAnswer(answer.id)}
                      />
                      <label
                        className="form-check-label answers-check  answer-grid-check"
                        htmlFor={answer.id}
                      >
                        <img
                          src={`./images/question-ten/${answer.answer}`}
                          alt=""
                        />
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              </div>
            </div>

            <div className="d-flex justify-content-center w-100 mt-2 question-fixed-bottom">
              <button
                className={`button-shadow ${
                  !selectedAnswer ? "shadow-gray" : "shadow-blue"
                }`}
                onClick={questionFinish}
                disabled={!selectedAnswer}
              >
                İlerle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridImageContext;
