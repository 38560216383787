import React from "react";
import { Link } from "react-router-dom";
import { Col, Progress } from "reactstrap";
import { useQuestionContext } from "../../context/QuestionContext";

const QuestionsHeader = () => {
    const {activeStep} = useQuestionContext()
  return (
    <div className="questions-header">
      <Link to={"/"} className="d-flex align-items-center gap-3">
        <img src="./images/edutest.svg" alt="edutest" />
      </Link>
      <Col md="6" className="mx-auto">
        <Progress max={47} value={activeStep} className="progress-primary" />
      </Col>
    </div>
  );
};

export default QuestionsHeader;
