import React from "react";

const FormStepper = (props) => {
  const children = React.Children.toArray(props.children);
  const activeChild =
    props.activeStep <= children.length ? children[props.activeStep] : null;
  return <div className="w-100 h-100">{activeChild}</div>;
};

export default FormStepper;
