import React, { useState } from "react";
import { useQuestionContext } from "../context/QuestionContext";
import QuestionsHeader from "./Layout/QuestionsHeader";

const ImageChoiceQuestion = ({ data }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const { activeStep, nextQuestionRequest } = useQuestionContext();
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  const questionFinish = () => {
    const datas = {
      question:activeStep + 1,
      answer:1,
      point:data.rightAnswer === selectedAnswer ? 10 : 0
    }
    nextQuestionRequest(datas)
  }
  return (
    <div className="before-questions">
      <QuestionsHeader />

      <div className="container ">
        <div className="question-area">
          <div className="d-flex flex-column align-items-center justify-content-center ">
            <p className="question-q mb-4">
              <span>{activeStep + 1 }.</span> {data.question}
            </p>
            <div className="row   w-100 mt-5">
              {data.answers.map((answer, key) => (
                <div className="col-lg-4 col-12 d-flex gap-2 mb-5" key={key}>
                  <span className="shk">{alphabet[key]}.</span>
                  <div className="form-check ps-0">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="flexRadioDefault"
                      id={answer.id}
                      onChange={() => setSelectedAnswer(answer.id)}
                    />
                    <label
                      className="form-check-label answers-check answers-check-image"
                      htmlFor={answer.id}
                    >
                      <img
                        src={`./images/question-nine/${answer.answer}`}
                        alt=""
                      />
                    </label>
                  </div>
                </div>
              ))}
            </div>

            
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100  question-fixed-bottom">
              <button
                className={`button-shadow ${
                  !selectedAnswer ? "shadow-gray" : "shadow-blue"
                }`}
                disabled={!selectedAnswer}
                onClick={questionFinish}
              >
                İlerle
              </button>
            </div>
    </div>
  );
};

export default ImageChoiceQuestion;
