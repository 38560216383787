import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";

const Kullanim = () => {
  return (
    <>
    <Helmet>
        <title> Kullanım Koşulları</title>
        <meta name="description" content="Eduvery Kullanım Koşulları" />
    </Helmet>
    <div className="fixed-page-wrapper">
      <Container>
        <Row>
          <Col xs="12">
            <div className="sozlesme">
              <h1 className="sozlesme-name">Giriş</h1>
              <p className="sozlesme-main-text">
                Eduvery.com’a hoş geldiniz. Eduvery.com interneti kullanan
                öğretmenlerin ve öğrencilerin belirlenen derslerle ilgili konu
                içeriklerine, yaprak testlere, online sınavlara ulaşmasını
                sağlamayı ve doğru bilgiye ulaşma bilincini kazandırmayı
                amaçlayan bir platformdur.
              </p>
              <article className="sozlesme-full">
                <h3 className="madde-baslik">Kullanım Koşulları </h3>
                Eduvery.com internet sitesini üye olup kullanarak işbu Kullanım
                Koşulları’nı, Gizlilik Politikası’nı ve kişisel verilerin
                korunmasına ilişkin metni bir bütün olarak kabul etmiş
                olursunuz. Bu sitede yer alan dosyaların bir kısmı doğrudan
                ziyaretçilerimizin kendileri tarafından, sitede yayınlanmaları
                için kaydedilmiştir. Diğer dosyalar ise sadece bilgi vermek
                amacıyla iyi niyetle sunulmaktadır. Bu site kapsamında 18
                yaşından küçükler veli ve/veya vasinin izin ve/veya onayı
                olmaksızın alım-satım işlemlerinin tarafı olamazlar. Eduvery.com
                kullanırken yürürlükteki mevzuatı ihlal etmeyeceğini,
                Eduvery.com’da yayınlanan hiçbir içeriği Eduvery.com’ dan izin
                almadan ticari amaçla olsun veya olmasın kullanmayacağını;
                editörler tarafından üretilen her neviden içerikleri başka
                internet sitelerinde, ortamlarda ve diğer mecralarda sahibinin
                adı ve bağlantı (link) belirtmeksizin yayınlayamayacağını,
                Eduvery.com markalarının tescilli markalar olduğunu ve izinsiz
                kullanılamayacağını, Herhangi bir yazılım, donanım veya iletişim
                unsuruna zarar vermek, işlevini aksatmak maksadıyla virüs içeren
                yazılım veya başka bir bilgisayar kodu, dosyası
                oluşturmayacağını, yetkisi olmayan herhangi bir sisteme ve/veya
                veriye ulaşmaya çalışmayacağını, Ayrıca direkt veya dolaylı
                olarak, verilen hizmetlerdeki algoritmaları ve kodları deşifre
                edecek, işlevlerini bozacak davranışlarda bulunmayacağını,
                İçerikleri değiştirme, dönüştürme, çevirme, alıntı
                göstermeksizin başka sitelerde yayınlama gibi davranışlarda
                bulunmayacağını, Eduvery.com adresinde yayınlanan içeriklerin
                paylaşılması sebebiyle doğacak olan tüm hukuki sorumluluğun
                paylaşan kişiye ait olduğunu, Eduvery.com’un hiçbir
                sorumluluğunun olmadığını, Eduvery.com birçok içeriğinden başka
                sitelere bağlantı (link) verilebileceği kabulü ile Eduvery.com
                tarafından bağlantı (link) verilen, tavsiye edilen diğer
                sitelerin bilgi kullanımı, gizlilik ilkeleri ve içeriğinden
                Eduvery.com’un sorumlu olmadığını, Eduvery.com’un kendi ürettiği
                veya dışardan aldığı bilgi, belge, yazılım, tasarım, grafik vb.
                eserlerin 5846 Sayılı Fikir ve Sanat Eserleri Kanunu kapsamında
                korunduğunu ve eser hakkının ihlali halinde bundan dolayı
                sorumlu olunacağını, 5846 sayılı kanunun 25. maddesinin ek 4.
                maddesine göre hakkı ihlal edilen öncelikle üç gün içinde
                ihlalin durdurulmasını istemek zorunda olduğunu, Eduvery.com’un
                kullanıcı üyeliği gerektirmeyen hizmetleri zaman içinde üyelik
                gerektiren bir hale dönüştürebileceğini, ilave hizmetler
                açabileceğini, bazı hizmetlerini kısmen veya tamamen
                değiştirebileceği veya ücretli hale dönüştürebileceğini,
                Kullanıcının içerik oluşturmasına izin verilen yorumlarda ya da
                forumlarda ya da diğer platformlarda içerik oluşturan kişilerin
                bu içerikten dolayı tamamen ve tek başına sorumlu olduğunu,
                Eduvery.com’un içerik oluşturan kullanıcı ile ilgili kısıtlama
                ve kullanıcı engelleme, silme hakkına sahip olduğunu,
                Eduvery.com’daki bilgilerin güncelliği, doğruluğu, şartları,
                kalitesi, performansı, pazarlanabilirliği, belli bir amaca
                uygunluğu ve diğer bilgi, hizmet veya ürünlere etkisi ile
                tamlığı ve/veya kesintisiz devamlılık, güncelleme, işlevsellik,
                doğruluk, hatasızlık hakkında herhangi bir şekilde, Eduvery.com
                tarafından açık ya da zımni olarak garanti verilmediğini ve
                taahhütte bulunulmadığını, Eduvery.com’un gerekli gördüğü
                zamanlarda hizmetleri geçici bir süre askıya alabileceğini veya
                tamamen durdurabileceğini, hizmetlerin geçici bir süre askıya
                alınması veya tamamen durdurulmasından dolayı kullanıcılara
                karşı herhangi bir sorumluluğunun olmadığını, Eduvery.com’un
                hizmetleri, tasarımı ve içeriği her zaman değiştirebilme veya
                silebilme hakkını saklı tuttuğunu ve sunulan hizmetlerin
                kullanıcılara kazanılmış hak tahsis etmeyeceğini, İçerik
                yükleyici, üye, kullanıcı, ziyaretçi veya sair diğer kişiler
                tarafından Eduvery.com’a yüklenilen her türlü bilgi, belge,
                içerik, yorum veya veriler arasında Türkiye Cumhuriyeti
                Devleti’nin gizli tutulması gereken bilgilerinden olan, Türkiye
                Cumhuriyeti Devleti’nin bekasına zarar verebilecek içeriklerden
                olan, ticaret şirketleri ve sair tüzel kişilerin ticari sırrı
                niteliğinde olan, üçüncü kişilerin kişilik haklarını zedeleyen,
                çıplaklık, müstehcenlik veya cinsellik içeren, diğer
                kullanıcıları, bir toplumu, ırkı veya topluluğu hedef alan
                tehdit veya taciz yahut nefret içerikli olan, herhangi bir
                siyasi ideoloji ve propaganda içerikli olan, herhangi bir
                sebeple de olsa örgütlenme masajı taşıyan her türlü bilgi,
                belge, içerik, yorum ve verilerin bulunması durumunda
                Eduvery.com’un hukuki ve/veya cezai sorumluluğunun olmadığını,
                bu konudaki sorumluluğun ilgili bilgi, belge, içerik, yorum veya
                veriyi gönderen kullanıcıda olduğunu, Sitenin kullanımı
                sırasında her ne sebeple olursa olsun ortaya çıkan her türlü
                zarar, ziyan ve masraftan Eduvery.com’un sorumlu olmayacağını,
                Eduvery.com’un kullanım koşulları ve kuralları her zaman tek
                taraflı değiştirme hakkının saklı olduğunu ve bu kapsamda, işbu
                Kullanım Koşulları’nın, Gizlilik Politikası’ nın ve Kişisel
                Verilerin Korunmasına İlişkin Aydınlatma Metni’ nin tamamını
                okuduğunu ve bir bütün olarak onayladığını, Eduvery.com’ a
                mümkün olan tüm mecralar vasıtası ile ulaşarak ayrıca onay
                gerekmeksizin belirtilen tüm metinleri ve de metinlerde
                belirtilen kural ve koşulları bir bütün olarak kabul, beyan ve
                taahhüt eder.
              </article>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

export default Kullanim;
