import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'

const Error = () => {
  return (
    <div className='fixed-page-wrapper'>
      <Container>
      <div style={{paddingTop:"200px",paddingBottom:"200px"}} className='d-flex align-items-center justify-content-center flex-column'>
          <h2> Aradınız Sayfa Bulunamadı</h2>
          <Link
                    to={"/"}
                    className="btn btn-lg btn-white mx-auto btn-darkblue mt-3"
                  >
                    <div className="d-flex gap-2 align-items-center">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="31"
                          viewBox="0 0 32 31"
                          fill="none"
                        >
                          <circle
                            cx="16.0005"
                            cy="15.1021"
                            r="15.1021"
                            fill="#3C3744"
                          />
                          <path
                            d="M17.4969 19.9973C17.3497 19.9973 17.2026 19.9431 17.0864 19.8269C16.8618 19.6023 16.8618 19.2306 17.0864 19.006L21.3769 14.7155L17.0864 10.4249C16.8618 10.2003 16.8618 9.82859 17.0864 9.60399C17.311 9.3794 17.6828 9.3794 17.9073 9.60399L22.6083 14.305C22.8329 14.5296 22.8329 14.9013 22.6083 15.1259L17.9073 19.8269C17.7912 19.9431 17.644 19.9973 17.4969 19.9973Z"
                            fill="white"
                          />
                          <path
                            d="M22.0643 15.2965H9.03007C8.71254 15.2965 8.44922 15.0331 8.44922 14.7156C8.44922 14.3981 8.71254 14.1348 9.03007 14.1348H22.0643C22.3818 14.1348 22.6451 14.3981 22.6451 14.7156C22.6451 15.0331 22.3818 15.2965 22.0643 15.2965Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <p className="mb-0">Anasayfa Dön</p>
                    </div>
                  </Link>
      </div>

      </Container>
    </div>
  )
}

export default Error