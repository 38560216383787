/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import "../assets/scss/beforeQuestions.scss";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useApi } from "../hooks/useApi";
import { handleStartQuizChangeState } from "../store/user";
import Cleave from "cleave.js/react";
import { toast } from "react-toastify";

const defaultValues = {
  cc_number: "",
  cc_cvc: "",
  cc_month: "",
  cc_year: "",
  cc_holder: "",
  name: "Refik Selim",
  surname: "Altıok",
  address: "Çınar mah. Bağdat cad. no 173 / 5",
  phone: "+9053505134190",
  city: "İstanbul",
  country: "Türkiye",
  zipcode: "34840",
  tckno: "11111111111",
};

const BeforeQuestions = () => {
  const [payment, setPayment] = useState(false);
  const [paymentCheck, setPaymentCheck] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quiz_id } = useSelector((state) => state.user);
  const [values, setValues] = useState(defaultValues);
  const handleStartQuiz = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi()
      .get("quiz/new")
      .then((res) => {
        if (res.data.status) {
          dispatch(handleStartQuizChangeState(res.data.quiz_id));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (quiz_id) {
      navigate("/questions");
    }
  }, [quiz_id]);

  const validationControl = () => {
    let isValid = true;
    const inputs = document.querySelectorAll(".payment-input");
    inputs.forEach((input) => {
      input.classList.remove("is-invalid");
      if (input.value === "") {
        isValid = false;

        input.classList.add("is-invalid");
      }
    });

    return isValid;
  };
  const sendPayment = () => {
    const validation = validationControl();
    if (!validation) {
      toast.error("Lütfen tüm alanları doldurunuz", {
        hideProgressBar: true,
        autoClose: 2000,
      });
      return false;
    }
    const formData = new FormData();
    for (const key in values) {
      if (key === "cc_number") {
        formData.append(key, values[key].replace(/\s/g, ""));
      } else {
        formData.append(key, values[key]);
      }
    }
    useApi()
      .post("account/doPayment", formData)
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.message, {
            hideProgressBar: true,
            autoClose: 2000,
          });
          handleStartQuiz();
        } else {
          handleStartQuiz();
        }
      })
      .catch((err) => {
        handleStartQuiz();
        toast.error(err);
      });
  };
  const handleValueChange = (e) => {
    setValues((value) => ({
      ...value,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    navigate("/questions")
  },[])

  return (
    <div className="before-questions">
      <div className="questions-header">
        <Link to={"/"} className="d-flex align-items-center gap-3">
          <img src="./images/edutest.svg" alt="edutest" />
        </Link>
      </div>
      <div className="main-area">
        {!payment ? (
          <Container className="h-100 d-flex justify-content-center align-items-center">
            <Row>
              <Col xs="12">
                <div className="payment-inform">
                  <h3 className="inform-title text-center">
                    Geleceğin formülü: Eduvery! <br /> Kendini test et, yönünü
                    belirle!
                  </h3>
                </div>
              </Col>

              <Col md="6">
                <h6 className="info-h6">
                  Eduvery Uzmanlar Tarafından Hazırlandı
                </h6>
                <h3 className="info-big-text">
                  Üniversite Bölüm Tercihinizi Şekillendirecek En Doğru Rehber!
                </h3>
                <p>
                  Edutest, bilgi birikiminizi, kişiliğinizi ve ilgi alanlarınızı
                  keşfederek, üniversite bölüm tercihinizi nasıl
                  kullanabileceğinizi gösterebilen bir testtir. Bu testle
                  geleceğiniz için kendinize yatırım yapabilir ve bir adım öne
                  geçebilirsiniz!
                </p>
              </Col>
              <Col md="6">
                <div className="">
                  <input
                    type="checkbox"
                    value={paymentCheck}
                    onChange={() => setPaymentCheck(!paymentCheck)}
                    className="d-none payment-check"
                    id="payment-check"
                  />
                  <label htmlFor="payment-check">
                    <div className="payment-card">
                      <div className="payment-card-body">
                        <Row>
                          <Col md="6">
                            <h3 className="card-title">
                              {" "}
                              Ödemeni Yap ve Hemen Teste Başla{" "}
                            </h3>
                            <p className="card-text">
                              Eduvery güvencesiyle, platformumuz üzerinden
                              sunulan hizmetler en güvenli ödeme altyapıları ile
                              gerçekleştirilir.
                            </p>
                          </Col>
                          <Col md="6">
                            <div className="d-flex align-items-center justify-content-center h-100">
                              <div className="d-flex align-items-center ">
                                <span className="price">1200₺</span>
                                <span className="subtext">/Tek Seferlik</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    onClick={() => setPayment(true)}
                    className={`button-shadow  ${
                      paymentCheck ? "shadow-blue" : "shadow-gray"
                    } `}
                    disabled={!paymentCheck}
                  >
                    ÖDEME YAP
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="payment-area">
            <Container>
              <Row>
                <Col md="8">
                  <div className="payment-card">
                    <div className="payment-card-body">
                      <h3 className="card-header">Ödeme Yönteminizi Seçiniz</h3>
                      <div className="d-flex payment-options">
                        <div className="payment-option">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="34"
                              height="34"
                              viewBox="0 0 34 34"
                              fill="none"
                            >
                              <path
                                d="M26.9154 18.9268H2.83203C2.2512 18.9268 1.76953 18.4451 1.76953 17.8643C1.76953 17.2834 2.2512 16.8018 2.83203 16.8018H26.9154C27.4962 16.8018 27.9779 17.2834 27.9779 17.8643C27.9779 18.4451 27.4962 18.9268 26.9154 18.9268Z"
                                fill="#838383"
                              />
                              <path
                                d="M21.5603 30.8126H8.18707C3.32791 30.8126 1.76953 29.2685 1.76953 24.466V14.5635C1.76953 10.7526 2.63373 8.50013 7.0254 8.24513C7.39373 8.23096 7.77624 8.2168 8.18707 8.2168H21.5603C26.4195 8.2168 27.9779 9.76096 27.9779 14.5635V24.6926C27.9212 29.3251 26.3628 30.8126 21.5603 30.8126ZM8.18707 10.3418C7.80457 10.3418 7.45041 10.356 7.12457 10.3701C4.58874 10.526 3.89453 11.0643 3.89453 14.5635V24.466C3.89453 28.0926 4.48957 28.6876 8.18707 28.6876H21.5603C25.2153 28.6876 25.8104 28.121 25.8529 24.6785V14.5635C25.8529 10.9368 25.2578 10.3418 21.5603 10.3418H8.18707Z"
                                fill="#838383"
                              />
                              <path
                                d="M26.9154 25.755C26.6462 25.755 26.377 25.6558 26.1929 25.4717C25.9804 25.2733 25.8529 24.99 25.8529 24.6925V14.5633C25.8529 10.9367 25.2578 10.3417 21.5603 10.3417H8.18707C7.80457 10.3417 7.45041 10.3558 7.12457 10.37C6.84124 10.3842 6.55784 10.2708 6.34534 10.0725C6.13284 9.87417 6.01953 9.59084 6.01953 9.29333C6.0762 4.675 7.63457 3.1875 12.4371 3.1875H25.8103C30.6695 3.1875 32.2279 4.73167 32.2279 9.53417V19.4367C32.2279 23.2475 31.3637 25.5 26.972 25.755C26.9578 25.755 26.9295 25.755 26.9154 25.755ZM8.18707 8.21667H21.5603C26.4195 8.21667 27.9779 9.76083 27.9779 14.5633V23.5167C29.6212 23.2192 30.1029 22.3692 30.1029 19.4367V9.53417C30.1029 5.9075 29.5078 5.3125 25.8103 5.3125H12.4371C9.20707 5.3125 8.37124 5.75167 8.18707 8.21667Z"
                                fill="#838383"
                              />
                              <path
                                d="M9.85859 26.293H7.42188C6.84104 26.293 6.35938 25.8113 6.35938 25.2305C6.35938 24.6496 6.84104 24.168 7.42188 24.168H9.85859C10.4394 24.168 10.9211 24.6496 10.9211 25.2305C10.9211 25.8113 10.4536 26.293 9.85859 26.293Z"
                                fill="#838383"
                              />
                              <path
                                d="M17.7796 26.293H12.9062C12.3254 26.293 11.8438 25.8113 11.8438 25.2305C11.8438 24.6496 12.3254 24.168 12.9062 24.168H17.7796C18.3604 24.168 18.8421 24.6496 18.8421 25.2305C18.8421 25.8113 18.3746 26.293 17.7796 26.293Z"
                                fill="#838383"
                              />
                            </svg>
                          </span>
                          Kredi Kartı
                        </div>
                      </div>
                      <Row>
                        <Col xs="12" className="mb-4">
                          <Label
                            htmlFor="namesurname"
                            className="payment-input-label"
                          >
                            Kart Üzerindeki İsim
                          </Label>
                          <Input
                            id="namesurname"
                            className="form-control payment-input"
                            value={values.cc_holder}
                            onChange={(e) =>
                              setValues((value) => ({
                                ...value,
                                cc_holder: e.target.value
                                  .replace(/[^a-zA-ZğüşöçĞÜŞİÖÇıİ\s]/g, "")
                                  .slice(0, 30),
                              }))
                            }
                          />
                        </Col>
                        <Col xs="12" className="mb-4">
                          <Label
                            htmlFor="cardnumber"
                            className="payment-input-label"
                          >
                            Kart Numarası
                          </Label>
                          <div className="position-relative">
                            <Cleave
                              options={{
                                creditCard: true,
                              }}
                              id="cardnumber"
                              type="text"
                              className="payment-input form-control"
                              name="cc_number"
                              value={values.cc_number}
                              onChange={(e) => handleValueChange(e)}
                            />
                            <span className="card-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="55"
                                height="33"
                                viewBox="0 0 55 33"
                                fill="none"
                              >
                                <path
                                  d="M54.9732 2.4665C54.9724 1.10252 53.8661 -0.000787093 52.5014 4.21319e-07L2.4687 0.0472513C1.10473 0.0488263 -0.00157425 1.15528 1.6816e-06 2.51847L0.0267925 30.5335C0.0283684 31.8967 1.13625 33.0016 2.49943 33L52.5321 32.9527C53.8984 32.9512 55.0024 31.8439 55 30.4807L54.9827 12.612L0.0086693 12.6648L0.00315354 5.10467L54.9771 5.05269L54.974 2.4665H54.9732ZM26.9098 15.388L26.9138 18.6279L3.33782 18.6499L3.33388 15.4101L26.9098 15.388Z"
                                  fill="#E5E5E5"
                                />
                                <path
                                  d="M54.9945 5L0 5.05462L0.00551781 13L55 12.9454L54.9968 10.7687L54.9945 5.00083V5Z"
                                  fill="#838383"
                                />
                                <path d="M27 15H3V19H27V15Z" fill="#838383" />
                              </svg>
                            </span>
                          </div>
                        </Col>
                        <Row className="justify-content-between">
                          <Col xs="3">
                            <Label htmlFor="ay" className="payment-input-label">
                              Ay
                            </Label>

                            <select
                              className="form-select payment-input"
                              id="ay"
                              name="cc_month"
                              value={values.cc_month}
                              onChange={(e) => handleValueChange(e)}
                            >
                              <option value={""}>Ay</option>
                              <option value="01">Ocak</option>
                              <option value="02">Şubat</option>
                              <option value="03">Mart</option>
                              <option value="04">Nisan</option>
                              <option value="05">Mayıs</option>
                              <option value="06">Haziran</option>
                              <option value="07">Temmuz</option>
                              <option value="08">Ağustos</option>
                              <option value="09">Eylül</option>
                              <option value="10">Ekim</option>
                              <option value="11">Kasım</option>
                              <option value="12">Aralık</option>
                            </select>
                          </Col>
                          <Col xs="3">
                            <Label
                              htmlFor="yıl"
                              className="payment-input-label"
                            >
                              Yıl
                            </Label>

                            <select
                              className="form-select payment-input"
                              id="yıl"
                              value={values.cc_year}
                              name="cc_year"
                              onChange={(e) => handleValueChange(e)}
                            >
                              <option value={""}>Yıl</option>
                              <option value="2024">2024</option>
                              <option value="2025">2025</option>
                              <option value="2026">2026</option>
                              <option value="2027">2027</option>
                              <option value="2028">2028</option>
                              <option value="2029">2029</option>
                              <option value="2030">2030</option>
                              <option value="2031">2031</option>
                              <option value="2032">2032</option>
                              <option value="2033">2033</option>
                              <option value="2034">2034</option>
                              <option value="2035">2035</option>
                              <option value="2036">2036</option>
                              <option value="2037">2037</option>
                              <option value="2038">2038</option>
                              <option value="2039">2039</option>
                              <option value="2040">2040</option>
                            </select>
                          </Col>
                          <Col xs="3">
                            <Label
                              htmlFor="cvv"
                              className="payment-input-label"
                            >
                              CVV
                            </Label>

                            <Cleave
                              id="cvv"
                              type="text"
                              className="payment-input form-control"
                              options={{ blocks: [3], numericOnly: true }}
                              name="cc_cvc"
                              value={values.cc_cvc}
                              onChange={(e) => handleValueChange(e)}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="d-flex flex-column h-100">
                    <div className="payment-card col">
                      <div className="payment-card-body">
                        <h3 className="card-header">Sipariş Özeti</h3>
                        <div className="order">
                          <div className="d-flex justify-content-between">
                            <h6 className="ordername">Eduvery Eğitim Testi </h6>
                            <span className="orderprice">1200₺</span>
                          </div>
                          <p className="orderinfo">
                            Üniversite Bölüm Tercihinizi Şekillendirecek En
                            Doğru Rehber!
                          </p>
                        </div>
                      </div>
                      <div className="payment-card-footer">
                        <p className="footer-text">
                          {" "}
                          Kontrat satın alımdan itibaren 1 yıl geçerlidir ve
                          otomatik olarak yenilenmez. Satın alarak Şartlar ve
                          Koşullarımızı kabul etmiş olursunuz.{" "}
                        </p>
                        <div className="d-flex justify-content-center gap-3 payment-date">
                          <span>24 Ocak 2024</span> | <span>12.00</span>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={sendPayment}
                      className="button-shadow shadow-blue mt-5 w-100"
                    >
                      ÖDEME YAP
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

export default BeforeQuestions;
