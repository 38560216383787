import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MovingCirclesCanvasMini = ({setStep}) => {
  const canvasRef = useRef(null);
  const [clickCount, setClickCount] = useState(0);
  const [outsideClick, setOutsideClick] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10); // Başlangıçta 10 saniye
  const colors = ["#58CC02", "#9702CC", "#43766C", "#CC5702", "#02B4CC"];
  const [selectedColor, setSelectedColor] = useState();
  const [start, setStart] = useState(false);
  useEffect(() => {
    if (start) {
      setSelectedColor(colors[Math.floor(Math.random() * colors.length)]);
      const updateTimer = () => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 0) {
            clearInterval(timerInterval);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      };

      const timerInterval = setInterval(updateTimer, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const radius = 25;

    const circles = colors.map((color) => ({
      x: Math.random() * (canvas.width - 2 * radius) + radius,
      y: Math.random() * (canvas.height - 2 * radius) + radius,
      dx: 2,
      dy: 2,
      radius,
      color,
    }));

    const drawCircle = (circle) => {
      context.beginPath();
      context.arc(circle.x, circle.y, circle.radius, 0, 2 * Math.PI);
      context.fillStyle = circle.color;
      if (circle.hasBorder) {
        // Seçilen dairenin rengini al
        const selectedColor = circle.color;

        // Seçilen dairenin renginin bir hafif tonu (opacity = 0.5) olarak ayarla
        const borderColor = `rgba(${parseInt(
          selectedColor.slice(1, 3),
          16
        )}, ${parseInt(selectedColor.slice(3, 5), 16)}, ${parseInt(
          selectedColor.slice(5, 7),
          16
        )}, 0.5)`;

        context.strokeStyle = borderColor;
        context.lineWidth = 10;
        context.stroke();
      }

      context.fill();
      context.closePath();
    };

    const areCirclesOverlapping = (circle1, circle2) => {
      const distance = Math.sqrt(
        (circle1.x - circle2.x) ** 2 + (circle1.y - circle2.y) ** 2
      );
      return distance < circle1.radius + circle2.radius;
    };

    const animateCircles = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
    
      circles.forEach((circle, index) => {
        drawCircle(circle);
    
        circle.x += circle.dx;
        circle.y += circle.dy;
    
        if (
          circle.x - circle.radius < 0 ||
          circle.x + circle.radius > canvas.width
        ) {
          circle.dx = -circle.dx;
        }
        if (
          circle.y - circle.radius < 0 ||
          circle.y + circle.radius > canvas.height
        ) {
          circle.dy = -circle.dy;
        }
    
        for (let i = index + 1; i < circles.length; i++) {
          if (circles[i] && areCirclesOverlapping(circle, circles[i])) {
            const overlap =
              circle.radius +
              circles[i].radius -
              Math.sqrt(
                (circle.x - circles[i].x) ** 2 + (circle.y - circles[i].y) ** 2
              );
            const angle = Math.atan2(
              circles[i].y - circle.y,
              circles[i].x - circle.x
            );
    
            // Dairelerin birbirine gömülmesini engellemek için
            circle.x -= (overlap / 2) * Math.cos(angle);
            circle.y -= (overlap / 2) * Math.sin(angle);
            circles[i].x += (overlap / 2) * Math.cos(angle);
            circles[i].y += (overlap / 2) * Math.sin(angle);
    
            // Hızları tersine çevirme işlemi kaldırıldı
            // circle.dx = -circle.dx;
            // circle.dy = -circle.dy;
            // circles[i].dx = -circles[i].dx;
            // circles[i].dy = -circles[i].dy;
          }
        }
      });
    
      requestAnimationFrame(animateCircles);
    };

    const handleCanvasClick = (event) => {
      const clickX = event.clientX - canvas.getBoundingClientRect().left;
      const clickY = event.clientY - canvas.getBoundingClientRect().top;

      const isOutsideClick = circles.every((circle) => {
        const distance = Math.sqrt(
          (circle.x - clickX) ** 2 + (circle.y - clickY) ** 2
        );
        return distance > circle.radius;
      });

      if (isOutsideClick) {
        setOutsideClick((prevOutsideClick) => prevOutsideClick + 1);
      } else {
        circles.forEach((circle, index) => {
          const distance = Math.sqrt(
            (circle.x - clickX) ** 2 + (circle.y - clickY) ** 2
          );
          if (distance <= circle.radius && circle.color === selectedColor) {
            circles[index].hasBorder = true;

            // 1 saniye sonra border'ı kaldırmak için
            setTimeout(() => {
              circles[index].hasBorder = false;
            }, 500);

            setClickCount((prevClickCount) => prevClickCount + 1);
          }
        });
      }
    };

    canvas.width = 1290;
    canvas.height = 540;

    canvas.addEventListener("click", handleCanvasClick);

    if(start){
      animateCircles();
    }

    return () => {
      canvas.removeEventListener("click", handleCanvasClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColor]);

  return (
    <>
      <div className="fixed-header px-5">
        <Link to={"/"} className="d-flex align-items-center gap-3">
          <img src="./images/edutest-white.svg" alt="edutest" />
        </Link>
        <div className="d-flex align-items-center gap-3">
          <div className="selected-color">
            <div
              className="color-box"
              style={{ backgroundColor: selectedColor }}
            ></div>
          </div>
          <p className="mb-0 text-white">Gördüğün renge kaç kere tıklayabilirsin ?</p>
        </div>
        <div className="time-left">
          <p className="time-text">{timeLeft}</p>
        </div>
      </div>
      <canvas ref={canvasRef} id="movingCircleMini" className="d-block" />
      {timeLeft <= 0 && (
        <div className="moving-circle-pointer absolute ">
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <button
              className="button-shadow shadow-blue"
              onClick={() => setStep(2)}
            >
              İleri
            </button>
          </div>
        </div>
      )}
      {!start && (
        <div className="moving-circle-pointer absolute ">
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <button
              className="button-shadow shadow-blue"
              onClick={() => setStart(true)}
            >
              Teste Başla
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MovingCirclesCanvasMini;
