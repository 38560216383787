import React from "react";
import { Col, Container, Row } from "reactstrap";

const About = () => {
  return (
    <div className="fixed-page-wrapper">
      <Container>
        <Row className="align-items-center pt-5">
          <div className="col-lg">
            <img
              src="./images/about.png"
              className="img-fluid"
              alt="eduvery-about-world"
            />
          </div>
          <div className="col-lg about-text-title text-lg-end">
            Eğitim yolculuğunuzda uygulayabileceğiniz ve kendinizi keşfederek
            geleceğinizi inşa edebileceğiniz yol haritası; Edutest!
          </div>
        </Row>
        <hr />
        <Row className="mt-5">
          <Col md="12">
            <p /* className="about-paragraph" */>
            Eduvery olarak inancımız, her bireyin eğitim hakkına sahip olması gerektiğine dayanmaktadır. Bu temel değeri benimseyen bir ekip olarak, Eduvery adını taşıyan platformumuzda, eğitim deneyimini en iyi şekilde sunma hedefiyle çalışıyoruz.
            </p>
            <p>
            Sitemizde bulunan çeşitli testler, öğrenme sürecini sadece bilgi edinme değil, aynı zamanda keyifli bir deneyim haline getirme amacını taşır. Bu sayede kullanıcılar, öğrenme sürecini daha etkili ve motivasyon dolu bir şekilde yaşayabilirler. Ancak sadece eğlenmekle kalmayıp, aynı zamanda kendi yeteneklerini geliştirme ve başarıya ulaşma fırsatları sunarak, Eduvery olarak amacımız her bireyin potansiyelini en üst düzeye çıkarmak ve eğitimle ilgili engelleri kaldırmaktır.
            </p>
            <p>
            Herkesin eğitimle ilgili eşit fırsatlara sahip olması gerektiğine olan bu güçlü inançla, Eduvery olarak sürekli olarak içeriklerimizi geliştiriyor ve kullanıcılarımızın eğitim hayatlarında daha etkili ve keyifli bir yolculuk yapmalarına katkı sağlamaya çalışıyoruz.
            </p>
          </Col>
          
        </Row>
      </Container>
    </div>
  );
};

export default About;
