import React, { useEffect, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import "../assets/scss/profile.scss";
import PasswordInput from "../components/PasswordInput";
import { useApi } from "../hooks/useApi";
import { useSelector } from "react-redux";
import moment from "moment/dist/moment";
import "moment/dist/locale/tr";
import { toast } from "react-toastify";
moment.locale("tr");

const ProfileUpdate = () => {
  const passwordRegex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const { userData } = useSelector((state) => state.user);
  const [values, setValues] = useState({});
  const [data, setData] = useState({});
  const getUserInfo = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi()
      .get(`account/accountInfo?target_student=${userData.id}`)
      .then((res) => {
        setValues(res.data?.student);
        setData(res.data?.student);
      });
  }
  useEffect(() => {
    getUserInfo()
  }, []);
  const handleValueChange = (e) => {
    setValues((value) => ({
      ...value,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    if (values.password === undefined && values.passwordRepeat === undefined) {
      delete values.password;
      delete values.passwordRepeat;
      
    } else {
      
      if(!passwordRegex.test(values.password)){
        toast.error("Lütfen geçerli bir şifre giriniz", {
          position:"top-center",
          hideProgressBar:true
        })
        return false
      }
      if (values.password !== values.passwordRepeat) {
        toast.error("iki şifre aynı olmalı",{
          position:"top-center",
          hideProgressBar:true
        });
        return false;
      }
    }

    for (const key in values) {
      formData.append(key, values[key]);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi()
      .put("account/accountInfo", formData)
      .then((res) => {
        if (res.data?.status) {
          getUserInfo()
          toast.success("Profiliniz başarıyla güncellenmiştir.",{
            position:"top-center",
            hideProgressBar:true
  
          })
        }else{
          toast.error("Profil şu anda güncellenemiyor, lütfen daha sonra tekrar deneyiniz.")
        }
      })
      .catch((err) => {
        toast.error("Profil şu anda güncellenemiyor, lütfen daha sonra tekrar deneyiniz.")
        console.log(err)});
  };

  return (
    <div className="fixed-page-wrapper">
      <div className="profile">
        <Container>
          <div className="row flex-lg-row flex-column ">
            <div className="col-12">
              <div className="profile-info d-flex flex-column">
                <div className="d-flex align-items-center gap-3 mx-auto">
                  <div className="profile-picture">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="150"
                        height="151"
                        viewBox="0 0 150 151"
                        fill="none"
                      >
                        <circle cx="75" cy="75.5" r="75" fill="#3B4EFF" />
                        <path
                          d="M75 0.5C33.6675 0.5 0 34.1675 0 75.5C0 116.833 33.6675 150.5 75 150.5C116.333 150.5 150 116.833 150 75.5C150 34.1675 116.333 0.5 75 0.5ZM45 38L60 53H75H90L105 38V83C105 99.5675 91.5675 113 75 113C87.8148 113 99.5502 117.594 108.662 125.217C99.0734 131.708 87.4935 135.5 75 135.5C62.5065 135.5 50.9266 131.708 41.3379 125.217C50.4498 117.594 62.1852 113 75 113C58.4325 113 45 99.5675 45 83V38ZM60 68C58.0109 68 56.1032 68.7902 54.6967 70.1967C53.2902 71.6032 52.5 73.5109 52.5 75.5C52.5 77.4891 53.2902 79.3968 54.6967 80.8033C56.1032 82.2098 58.0109 83 60 83C61.9891 83 63.8968 82.2098 65.3033 80.8033C66.7098 79.3968 67.5 77.4891 67.5 75.5C67.5 73.5109 66.7098 71.6032 65.3033 70.1967C63.8968 68.7902 61.9891 68 60 68ZM90 68C88.0109 68 86.1032 68.7902 84.6967 70.1967C83.2902 71.6032 82.5 73.5109 82.5 75.5C82.5 77.4891 83.2902 79.3968 84.6967 80.8033C86.1032 82.2098 88.0109 83 90 83C91.9891 83 93.8968 82.2098 95.3033 80.8033C96.7098 79.3968 97.5 77.4891 97.5 75.5C97.5 73.5109 96.7098 71.6032 95.3033 70.1967C93.8968 68.7902 91.9891 68 90 68ZM75 83C73.0109 83 71.1032 83.3951 69.6967 84.0983C68.2902 84.8016 67.5 85.7554 67.5 86.75C67.5 87.7446 68.2902 88.6984 69.6967 89.4017C71.1032 90.1049 73.0109 90.5 75 90.5C76.9891 90.5 78.8968 90.1049 80.3033 89.4017C81.7098 88.6984 82.5 87.7446 82.5 86.75C82.5 85.7554 81.7098 84.8016 80.3033 84.0983C78.8968 83.3951 76.9891 83 75 83Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="profile-data">
                    <h4 className="user-name">
                      {data.name} {data.surname}
                    </h4>
                    <div className="d-flex align-items-center gap-2">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12.5"
                            r="10"
                            stroke="white"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M12 8.5V12.5L14.5 15"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <p className="mb-0">
                        {moment(data.date_added).format("Do MMMM")} tarihinde
                        katıldı
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"> </div>
            <div className="d-flex ">
              <Col md="4" className="mx-auto">
                <Row tag={"form"} onSubmit={handleSubmit}>
                  <Col xs="12" className="mb-3">
                    <Label htmlFor="name">İsim</Label>
                    <Input
                      id="name"
                      className="profile-update-input"
                      value={values.name}
                      name="name"
                      onChange={(e) => {
                        handleValueChange(e);
                      }}
                    />
                  </Col>
                  <Col xs="12" className="mb-3">
                    <Label htmlFor="surname">Soy isim</Label>
                    <Input
                      id="surname"
                      className="profile-update-input"
                      value={values.surname}
                      name="surname"
                      onChange={(e) => {
                        handleValueChange(e);
                      }}
                    />
                  </Col>
                  <Col xs="12" className="mb-3">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      className="profile-update-input"
                      value={values.email}
                      name="email"
                      onChange={(e) => {
                        handleValueChange(e);
                      }}
                    />
                  </Col>
                  {!passwordUpdate && (
                    <Col xs="12">
                      <button
                        className="btn password-reset-btn"
                        type="button"
                        onClick={() => setPasswordUpdate(!passwordUpdate)}
                      >
                        Şifreni Değiştir
                      </button>
                    </Col>
                  )}
                  {passwordUpdate && (
                    <>
                      <Col xs="12" className="mb-3">
                        <Label htmlFor="password">Şifre</Label>
                        <PasswordInput
                          className="profile-update-input"
                          id="password"
                          value={values.password}
                          name="password"
                          onChange={(e) => {
                            handleValueChange(e);
                          }}
                        />
                      </Col>
                      <Col xs="12" className="mb-3">
                        <Label htmlFor="passwordRepeat">Şifre Tekrar</Label>
                        <PasswordInput
                          className="profile-update-input"
                          id="passwordRepeat"
                          value={values.passwordRepeat}
                          name="passwordRepeat"
                          onChange={(e) => {
                            handleValueChange(e);
                          }}
                        />
                      </Col>
                    </>
                  )}
                  <div className="col-12 d-flex mx-auto mt-3">
                    <button
                      type="submit"
                      className="btn btn-lg btn-white mx-auto btn-darkblue"
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="31"
                            viewBox="0 0 32 31"
                            fill="none"
                          >
                            <circle
                              cx="16.0005"
                              cy="15.1021"
                              r="15.1021"
                              fill="#3C3744"
                            />
                            <path
                              d="M17.4969 19.9973C17.3497 19.9973 17.2026 19.9431 17.0864 19.8269C16.8618 19.6023 16.8618 19.2306 17.0864 19.006L21.3769 14.7155L17.0864 10.4249C16.8618 10.2003 16.8618 9.82859 17.0864 9.60399C17.311 9.3794 17.6828 9.3794 17.9073 9.60399L22.6083 14.305C22.8329 14.5296 22.8329 14.9013 22.6083 15.1259L17.9073 19.8269C17.7912 19.9431 17.644 19.9973 17.4969 19.9973Z"
                              fill="white"
                            />
                            <path
                              d="M22.0643 15.2965H9.03007C8.71254 15.2965 8.44922 15.0331 8.44922 14.7156C8.44922 14.3981 8.71254 14.1348 9.03007 14.1348H22.0643C22.3818 14.1348 22.6451 14.3981 22.6451 14.7156C22.6451 15.0331 22.3818 15.2965 22.0643 15.2965Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <p className="mb-0">Kaydet</p>
                      </div>
                    </button>
                  </div>
                </Row>
              </Col>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ProfileUpdate;
