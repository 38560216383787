import React, { useMemo, useState } from "react";
import QuestionsHeader from "../Layout/QuestionsHeader";
import { useQuestionContext } from "../../context/QuestionContext";

const MultipleQuestion = ({ data }) => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [fullAnswers, setFullAnswers] = useState([]);
  const { nextQuestionRequest, activeStep } = useQuestionContext();

  const removeCheck = () => {
    document.querySelectorAll(".form-check-input").forEach((check) => {
      check.checked = false;
    });
  };

  const nextQuestion = () => {
    if (activeQuestion + 1 === data.questions.length) {
      setFullAnswers((answer) => [
        ...answer,
        (answer[activeQuestion] = selectedAnswer),
      ]);
      let point = 0;
      for (let index = 0; index < fullAnswers.length; index++) {
        if (fullAnswers[index] === data.questions[index].rightAnswer) {
          point = point + 2.5;
        }
      }
      const datas = {
        question: activeStep + 1,
        answer: 1,
        point: point,
      };
      nextQuestionRequest(datas);
    } else {
      setFullAnswers((answer) => [
        ...answer,
        (answer[activeQuestion] = selectedAnswer),
      ]);
      setActiveQuestion((active) => active + 1);
      setSelectedAnswer("");
      removeCheck();
    }
  };
  const renderPage = useMemo(() => {
    return(
      <div className="d-flex flex-column w-100  h-100"
          >
            <div className="d-flex align-items-center justify-content-center">
                <img src={`./images/question-tetris/${data.questions[activeQuestion].questionImage}`} />
            </div>
            <h3 className="question-q my-5">6.{activeQuestion + 1}. {data.questions[activeQuestion].question}</h3>
            <div className="row" >
            {data.questions[activeQuestion].answers.map((answer, key) => (
                <div className="col-lg-3 col-12 d-flex gap-2 mb-4" key={key}>
                  <span className="shk">{alphabet[key]}.</span>
                  <div className="form-check ps-0">
                    <input
                      className="form-check-input d-none h-100"
                      type="radio"
                      name="flexRadioDefault"
                      id={answer.id}
                      onChange={() => setSelectedAnswer(answer.id)}

                    />
                    <label
                      className="form-check-label answers-check answers-check-image col d-flex justify-content-center px-3 h-100"
                      htmlFor={answer.id}
                    >
                      <img
                        src={`./images/question-tetris/${answer.answerImage}`}
                        alt=""
                        style={{objectFit:"contain"}}
                      />
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
    )
  },[activeQuestion])

  return (
    <div className="before-questions">
      <QuestionsHeader />
      <div className="container">
        <div className="question-area">
          {renderPage}
        </div>
      </div>
      <div className="d-flex justify-content-center w-100  question-fixed-bottom">
        <button
          className={`button-shadow ${
            !selectedAnswer ? "shadow-gray" : "shadow-blue"
          }`}
          disabled={!selectedAnswer}
          onClick={nextQuestion}
        >
          İlerle
        </button>
      </div>
    </div>
  );
};

export default MultipleQuestion;
