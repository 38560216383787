import React from "react";
import { QuestionsContextProvider } from "../context/QuestionContext";
import Questions from "../components/Providers/Questions";

const QuestionsPage = () => {
  return (
    <QuestionsContextProvider>
      <Questions />
    </QuestionsContextProvider>
  );
};

export default QuestionsPage;
