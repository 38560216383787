import React, { useState } from "react";
import { useQuestionContext } from "../context/QuestionContext";
import QuestionsHeader from "./Layout/QuestionsHeader";

const ChoiceQuestion = ({ data }) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const { activeStep,nextQuestionRequest } = useQuestionContext();
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  const questionFinish = () => {
    const totalPoint = data.rightAnswer  ? data.rightAnswer === selectedAnswer ? 10 : 0 : data.answers.find(a => a.id === selectedAnswer).point
    const datas = {
      question:activeStep + 1,
      answer:1,
      point: totalPoint
    }
    nextQuestionRequest(datas)
  }
  return (
    <div className="before-questions">
      <QuestionsHeader />

      <div className="container">
        <div className="question-area">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <h6 className="question-q mb-4">
              <span>{activeStep + 1 }.</span> {data.question}
            </h6>
            {data.image && <div className="d-flex justify-content-center mb-4">
              <img  src={`./images/${data.image}`} alt="eduvery-question-three-graph"/>
            </div>}
            <div className="d-flex flex-column gap-2 w-100 mt-3">
              {data.answers.map((answer, key) => (
                <div className="form-check  ps-0" key={key}>
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="flexRadioDefault"
                    id={answer.id}
                    onChange={() => setSelectedAnswer(answer.id)}
                  />
                  <label
                    className="form-check-label answers-check"
                    htmlFor={answer.id}
                  >
                    <span className="alphabet">{alphabet[key]}.</span>
                    {" "}{answer.answer}
                  </label>
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-center w-100 question-fixed-bottom">
              <button
                className={`button-shadow ${
                  !selectedAnswer ? "shadow-gray" : "shadow-blue"
                }`}
                disabled={!selectedAnswer}
                onClick={questionFinish}
              >
                İlerle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoiceQuestion;
