import axios from "axios";

const token = localStorage.getItem("edu_user_t");
export const useApi = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "x-access-token": `${token ? token : ""}`,
      "Content-Type": "multipart/form-data"
    },
  });
};
