import React, { useState } from "react";
import { Input } from "reactstrap";
import { FaRegEye,FaRegEyeSlash } from "react-icons/fa6";

const PasswordInput = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="position-relative" style={{zIndex:2}}>
      <Input className="register-input register-password" type={visible ? "text" : "password"} {...props}  />
      <span type="button" onClick={() => setVisible(!visible)} className="password-visible" >
        {
            !visible  ? <FaRegEye /> : <FaRegEyeSlash />
        }
      </span>
    </div>
  );
};

export default PasswordInput;
